import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { withTranslation } from "react-multi-lang";
import fetchClient from "../../utils/fetchClient";
import FormControl from "@mui/material/FormControl";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import AuthService from "../../services/auth/AuthService";

import moment from "moment";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import ImageCompressor from "uppy-plugin-image-compressor";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { withRouter } from "../../hocs/withRouter";

const Auth = new AuthService();

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  content: {
    padding: theme.spacing()
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: 10
  }
});

class Company extends React.Component {
  constructor() {
    super();

    this.state = {
      isDisabled: true,
      isLoading: true,
      notesList: [],
      showAll: false,
      newCompanyNote: {
        note: "",
        images: []
      }
    };

    this.submitNote = this.submitNote.bind(this);
    this.handleChange = this.handleChange.bind(this);

    var decoded = Auth.getProfile();
    this.state.userId = decoded.userId;
  }

  handleChange = event => {
    this.setState(
      {
        newCompanyNote: {
          ...this.state.newCompanyNote,
          [event.target.name]: event.target.value
        }
      },
      () => {
        this.checkforErrors();
      }
    );
  };

  handleToggle = event => {
    this.setState(
      {
        isLoading: true,
        showAll: event.target.checked
      },
      () => {
        this.refetchNotes();
      }
    );
  };

  submitNote() {
    var self = this;

    self.setState({
      isDisabled: true,
      newCompanyNote: { note: "", images: [] }
    });

    fetchClient
      .post("ca/company/add/" + self.props.params.id, {
        ...self.state.newCompanyNote,
        cleaningId: self.props.params.id
      })
      .then(function (response) {
        self.refetchNotes();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async componentDidMount() {
    let notes = await this.fetchNotes();

    this.setState({
      notesList: notes.data.reverse(),
      isLoading: false
    });
  }

  async refetchNotes() {
    let notes = await this.fetchNotes();

    this.setState({
      notesList: notes.data.reverse(),
      isLoading: false
    });
  }

  async fetchNotes() {
    var self = this;
    return new Promise(function (resolve, reject) {
      fetchClient
        .post("/ca/company/view/" + self.props.params.id, {
          viewCreator: self.state.showAll ? 0 : self.state.userId
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  UNSAFE_componentWillMount() {
    this.noteimageuploader = new Uppy({
      id: "companyimage",
      autoProceed: true
    })
      .use(ImageCompressor, {
        maxWidth: 1000,
        quality: 0.6
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          return fetchClient
            .post(
              "upload",
              JSON.stringify({
                destination: "company_images",
                upload_type: "company",
                filename: file.name,
                contentType: file.type
              })
            )
            .then(response => {
              return response.data;
            })
            .then(data => {
              return {
                method: data.method,
                url: data.url,
                fields: data.fields,
                resultId: data.resultId
              };
            });
        }
      })
      .on("upload-success", (file, resp) => {
        this.setState({
          newCompanyNote: {
            ...this.state.newCompanyNote,
            images: [...this.state.newCompanyNote.images, resp.uploadURL]
          }
        });
      });

    this.fetchNotes();
  }

  componentWillUnmount() {
    this.noteimageuploader.close();
  }

  checkforErrors() {
    let errorCount = 0;

    if (!this.state.newCompanyNote.note) {
      errorCount++;
    }

    this.setState({ isDisabled: errorCount > 0 ? true : false });
  }

  render() {
    const { classes, t } = this.props;
    const { noteimageuploader } = this;

    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.navigate("/");
              }}
              className={classes.menuButton}
              color="inherit"
              aria-label={t("misc.Back")}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {!this.state.isLoading ? t("titles.Company") : t("titles.Loading") + "..."}
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.isLoading && <LinearProgress color="secondary" />}

        <div className={classes.content}>
          {!this.state.isLoading ? (
            <div>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  label={t("company.Note")}
                  multiline
                  onChange={this.handleChange}
                  name="note"
                  value={this.state.newCompanyNote.note}
                  error={!Boolean(this.state.newCompanyNote.note)}
                />
              </FormControl>
              <br />
              <DragDrop
                uppy={noteimageuploader}
                locale={{
                  strings: {
                    dropHereOr: t("company.Upload") + " %{browse}",
                    browse: t("company.Browse")
                  }
                }}
              />
              <StatusBar uppy={noteimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
              <div>
                {this.state.newCompanyNote.images.map((img, index) => {
                  return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                })}
              </div>

              <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={this.state.isDisabled} onClick={this.submitNote}>
                {t("company.Add")}
              </Button>

              <br />
              <br />

              <FormControlLabel control={<Switch checked={this.state.showAll} onChange={this.handleToggle} value="1" />} label={t("company.ShowAll")} />
              <Typography variant="h6" color="inherit" className={classes.grow}>
                {this.state.showAll ? t("company.AllNotes") : t("company.MyNotes")}
              </Typography>

              {this.state.notesList && this.state.notesList.length > 0 ? (
                this.state.notesList.map((note, index) => {
                  return (
                    <div key={index}>
                      <p key={note.noteId}>
                        {moment(note.dateCreated, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {note.noteContent}
                      </p>
                      {note.images.map((image, index) => {
                        return (
                          <img
                            style={{
                              paddingLeft: 2,
                              paddingRight: 2
                            }}
                            height="50"
                            width="50"
                            className="uploaded-image"
                            key={index}
                            src={image}
                            alt={image.split("/").pop()}
                          />
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <p> {t("company.NoNotes")}</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withStyles(styles)(Company)));
