import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { withTranslation } from "react-multi-lang";
import fetchClient from "../../utils/fetchClient";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import AuthService from "../../services/auth/AuthService";

import moment from "moment";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import ImageCompressor from "uppy-plugin-image-compressor";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { withRouter } from "../../hocs/withRouter";

const Auth = new AuthService();

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  content: {
    padding: theme.spacing()
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: 10
  }
});

class Maintenance extends React.Component {
  constructor() {
    super();

    this.state = {
      isDisabled: true,
      isLoading: true,
      tasksList: [],
      showAll: true,
      newTask: {
        taskDesc: "",
        taskPriority: 0,
        propertyId: "",
        taskFiles: []
      }
    };

    this.submitIssue = this.submitIssue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    var decoded = Auth.getProfile();
    this.state.userId = decoded.userId;
  }

  handleChange = event => {
    this.setState(
      {
        newTask: {
          ...this.state.newTask,
          [event.target.name]: event.target.value
        }
      },
      () => {
        this.checkforErrors();
      }
    );
  };

  handleToggle = event => {
    this.setState(
      {
        isLoading: true,
        showAll: event.target.checked
      },
      () => {
        this.refetchTasks();
      }
    );
  };

  handleSelect = val => {
    this.setState(
      {
        newTask: {
          ...this.state.newTask,
          propertyId: val ? val.value : null
        }
      },
      () => {
        this.checkforErrors();
      }
    );
  };

  submitIssue() {
    var self = this;

    self.setState({
      isDisabled: true,
      newTask: { taskDesc: "", taskFiles: [], taskPriority: 0 }
    });

    fetchClient
      .post("ma/new", {
        ...self.state.newTask,
        propertyId: self.props.params.id,
        taskAssignedTo: 2847
      })
      .then(function (response) {
        self.refetchTasks();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async componentDidMount() {
    let tasks = await this.fetchTasks();

    this.setState({
      tasksList: tasks.data,
      isLoading: false
    });
  }

  async refetchTasks() {
    let tasks = await this.fetchTasks();

    this.setState({
      tasksList: tasks.data,
      isLoading: false
    });
  }

  async fetchTasks() {
    var self = this;
    return new Promise(function (resolve, reject) {
      fetchClient
        .post("ma/list", {
          viewType: 0,
          viewProperty: self.props.params.id,
          viewCreator: self.state.showAll ? 0 : self.state.userId
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  UNSAFE_componentWillMount() {
    this.taskimageuploader = new Uppy({ id: "maimage", autoProceed: true })
      .use(ImageCompressor, {
        maxWidth: 1000,
        quality: 0.6
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          return fetchClient
            .post(
              "upload",
              JSON.stringify({
                destination: "ma",
                upload_type: "ma",
                filename: file.name,
                contentType: file.type
              })
            )
            .then(response => {
              return response.data;
            })
            .then(data => {
              return {
                method: data.method,
                url: data.url,
                fields: data.fields,
                resultId: data.resultId
              };
            });
        }
      })
      .on("upload-success", (file, resp) => {
        this.setState({
          newTask: {
            ...this.state.newTask,
            taskFiles: [...this.state.newTask.taskFiles, resp.uploadURL]
          }
        });
      });

    this.fetchTasks();
  }

  componentWillUnmount() {
    this.taskimageuploader.close();
  }

  checkforErrors() {
    let errorCount = 0;

    if (!this.state.newTask.taskDesc) {
      errorCount++;
    }

    this.setState({ isDisabled: errorCount > 0 ? true : false });
  }

  render() {
    const { classes, t } = this.props;
    const { taskimageuploader } = this;

    let colors = {
      0: "black",
      1: "orange",
      2: "red"
    };
    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.navigate("/");
              }}
              className={classes.menuButton}
              color="inherit"
              aria-label={t("misc.Back")}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {!this.state.isLoading ? t("titles.Report") : t("titles.Loading") + "..."}
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.isLoading && <LinearProgress color="secondary" />}

        <div className={classes.content}>
          {!this.state.isLoading ? (
            <div>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  required
                  label={t("task.TaskLabel")}
                  multiline
                  className={classes.formControl}
                  onChange={this.handleChange}
                  name="taskDesc"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.newTask.taskDesc}
                  error={!Boolean(this.state.newTask.taskDesc)}
                />
              </FormControl>
              <br /> <br />
              <FormControl fullWidth required className={classes.formControl}>
                <InputLabel htmlFor="taskPriority">{t("task.TaskPriority")}</InputLabel>
                <Select
                  native
                  value={this.state.newTask.taskPriority}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "taskPriority",
                    id: "taskPriority"
                  }}
                >
                  <option value={0}>{t("task.TaskPriorityLow")}</option>
                  <option value={1}>{t("task.TaskPriorityMedium")}</option>
                  <option value={2}>{t("task.TaskPriorityHigh")}</option>
                </Select>
              </FormControl>
              <DragDrop
                uppy={taskimageuploader}
                locale={{
                  strings: {
                    dropHereOr: t("task.Upload") + " %{browse}",
                    browse: t("task.Browse")
                  }
                }}
              />
              <StatusBar uppy={taskimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
              <div>
                {this.state.newTask.taskFiles.map((img, index) => {
                  return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                })}
              </div>
              <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={this.state.isDisabled} onClick={this.submitIssue}>
                {t("task.SubmitTask")}
              </Button>
              <br />
              <br />
              <FormControlLabel control={<Switch checked={this.state.showAll} onChange={this.handleToggle} value="1" />} label={t("task.ShowAll")} />
              <Typography variant="h6" color="inherit" className={classes.grow}>
                {this.state.showAll ? t("task.AllTasks") : t("task.MyTasks")}
              </Typography>
              {this.state.tasksList && this.state.tasksList.length > 0 ? (
                this.state.tasksList.map((task, index) => {
                  return (
                    <div key={index}>
                      <p
                        style={{
                          color: colors[task.taskPriority],
                          textDecoration: task.taskStatus >= 2 ? "line-through" : null
                        }}
                        key={task.taskId}
                      >
                        {moment(task.taskCreation, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {task.taskDesc}
                      </p>
                      {task.taskFiles.map((image, index) => {
                        return (
                          <img
                            style={{
                              paddingLeft: 2,
                              paddingRight: 2
                            }}
                            height="50"
                            width="50"
                            className="uploaded-image"
                            key={index}
                            src={image}
                            alt={image.split("/").pop()}
                          />
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <p> {t("task.NoTasks")}</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Maintenance.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withStyles(styles)(Maintenance)));
