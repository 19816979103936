import React from "react";
import { useNavigate } from "react-router-dom";

// ** Material UI
import { MenuItem, Menu, useTheme } from "@mui/material";

// ** Third party components
import { useTranslation } from "react-multi-lang";

export const CleaningMenu = ({ anchorEl, open, onClose, toggleDrawer, logout, filtersCount, userName, accessLevel }) => {
  // ** Hooks
  const theme = useTheme();
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={onClose}>
      <MenuItem
        onClick={() => {
          navigate("/cleaning-overview/");
        }}
      >
        <p>{t("nav.CleaningOverview")}</p>
      </MenuItem>

      {accessLevel > 0 && (
        <div>
          <MenuItem
            onClick={() => {
              navigate("/maintenance-overview/");
            }}
          >
            <p>{t("nav.MaintenanceOverview")}</p>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/company-overview/");
            }}
          >
            <p>{t("nav.CompanyOverview")}</p>
          </MenuItem>
        </div>
      )}

      <MenuItem
        onClick={() => {
          navigate("/laundry-overview/");
        }}
      >
        <p>{t("nav.LaundryOverview")}</p>
      </MenuItem>

      <MenuItem
        style={{
          display: "flex",
          [theme.breakpoints.up("md")]: {
            display: "none"
          }
        }}
        onClick={toggleDrawer}
      >
        <p>
          {t("nav.Filter")} {filtersCount > 0 ? "(" + filtersCount + ")" : null}{" "}
        </p>
      </MenuItem>

      <MenuItem
        onClick={() => {
          navigate("/settings/");
        }}
      >
        <p>{t("nav.Settings")}</p>
      </MenuItem>

      <MenuItem onClick={logout}>
        <p>{t("nav.Logout", { param: userName })}</p>
      </MenuItem>
    </Menu>
  );
};
