import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { withTranslation } from "react-multi-lang";
import fetchClient from "../utils/fetchClient";
import LinearProgress from "@mui/material/LinearProgress";

import AuthService from "../services/auth/AuthService";

import { withRouter } from "../hocs/withRouter";
import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import ImageCompressor from "uppy-plugin-image-compressor";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

const Auth = new AuthService();

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  content: {
    padding: theme.spacing()
  },
  button: {
    marginTop: 10
  }
});

class DirtyApartment extends React.Component {
  constructor() {
    super();

    this.state = {
      isDisabled: true,
      isLoading: true,
      photosList: [],
      newApartmentPhotos: []
    };

    this.submitPhotos = this.submitPhotos.bind(this);

    var decoded = Auth.getProfile();
    this.state.userId = decoded.userId;
  }

  submitPhotos() {
    var self = this;

    self.setState({
      isDisabled: true,
      newApartmentPhotos: []
    });

    fetchClient
      .post("ca/dirty-apartment/add/" + self.props.params.id, { images: self.state.newApartmentPhotos })
      .then(function (response) {
        self.refetchPhotos();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async componentDidMount() {
    let photos = await this.fetchPhotos();
    this.setState({
      photosList: photos.data.reverse(),
      isLoading: false
    });
  }

  async refetchPhotos() {
    let photos = await this.fetchPhotos();

    this.setState({
      photosList: photos.data.reverse(),
      isLoading: false
    });
  }

  async fetchPhotos() {
    var self = this;
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("/ca/dirty-apartment/view/" + self.props.params.id)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  UNSAFE_componentWillMount() {
    this.apartmentimageuploader = new Uppy({
      id: "apartmentimage",
      autoProceed: true
    })
      .use(ImageCompressor, {
        maxWidth: 1000,
        quality: 0.6
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          return fetchClient
            .post(
              "upload",
              JSON.stringify({
                destination: "dirty_apartment",
                upload_type: "dirty_apartment",
                filename: file.name,
                contentType: file.type
              })
            )
            .then(response => {
              return response.data;
            })
            .then(data => {
              return {
                method: data.method,
                url: data.url,
                fields: data.fields,
                resultId: data.resultId
              };
            });
        }
      })
      .on("upload-success", (file, resp) => {
        this.setState({
          newApartmentPhotos: [...this.state.newApartmentPhotos, resp.uploadURL]
        });

        this.checkforErrors();
      });

    this.fetchPhotos();
  }

  componentWillUnmount() {
    this.apartmentimageuploader.close();
  }

  checkforErrors() {
    let errorCount = 0;

    if (this.state.newApartmentPhotos.length === 0) {
      errorCount++;
    }

    this.setState({ isDisabled: errorCount > 0 ? true : false });
  }

  render() {
    const { classes, t } = this.props;
    const { apartmentimageuploader } = this;

    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.navigate("/");
              }}
              className={classes.menuButton}
              color="inherit"
              aria-label={t("misc.Back")}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {!this.state.isLoading ? t("titles.DirtyApartment") : t("titles.Loading") + "..."}
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.isLoading && <LinearProgress color="secondary" />}

        <div className={classes.content}>
          {!this.state.isLoading ? (
            <div>
              <p>{t("dirtyapartment.Explanation")}</p>
              <DragDrop
                uppy={apartmentimageuploader}
                locale={{
                  strings: {
                    dropHereOr: t("dirtyapartment.Upload") + " %{browse}",
                    browse: t("dirtyapartment.Browse")
                  }
                }}
              />
              <StatusBar uppy={apartmentimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
              <div>
                {this.state.newApartmentPhotos.map((img, index) => {
                  return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                })}
              </div>

              <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={this.state.isDisabled} onClick={this.submitPhotos}>
                {t("dirtyapartment.Add")}
              </Button>

              <br />
              <br />

              {this.state.photosList && this.state.photosList.length > 0 ? (
                this.state.photosList.map((photo, index) => {
                  return (
                    <img
                      index={index}
                      style={{
                        paddingLeft: 2,
                        paddingRight: 2
                      }}
                      height="50"
                      width="50"
                      className="uploaded-image"
                      key={index}
                      src={photo}
                      alt={photo.split("/").pop()}
                    />
                  );
                })
              ) : (
                <p>{t("dirtyapartment.NoPhotos")}</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

DirtyApartment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withStyles(styles)(DirtyApartment)));
