import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import DateRange from "@mui/icons-material/DateRange";
import Note from "@mui/icons-material/Note";
import Person from "@mui/icons-material/Person";
import LinearProgress from "@mui/material/LinearProgress";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ReactSelect from "react-select";
import FilterList from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@material-ui/pickers";

import moment from "moment";
import "moment/locale/es";

import { withTranslation, getLanguage } from "react-multi-lang";

import AuthService from "../../services/auth/AuthService";

import fetchClient from "../../utils/fetchClient";
import { withRouter } from "../../hocs/withRouter";

const Auth = new AuthService();

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    flexDirection: "column"
  },
  root: {
    width: "100%"
  },
  date: {
    marginTop: 10,
    padding: 15,
    color: "#4e5e9e"
  },
  dateBox: {
    margin: theme.spacing(1)
  },
  drawer: {
    width: 250
  },
  drawerInput: {
    margin: theme.spacing(1)
  }
});

function Select({ defaultValue, options, ...otherProps }) {
  return (
    <ReactSelect
      defaultValue={
        defaultValue && {
          value: defaultValue,
          label: options.find(o => o.value === defaultValue).label
        }
      }
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#70abdb",
          primary50: "#5b9fd6",
          primary75: "#4793d1",
          primary: "#3388cc"
        }
      })}
      options={options}
      {...otherProps}
    />
  );
}

const localeMap = {
  en: "en",
  es: "es"
};
class LaundryOverviewList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      laundryOverviewList: [],
      propertiesList: [],
      buildingsList: [],
      companiesList: [],
      cleanersList: [],
      filters: {
        viewProperty: 0,
        viewBuilding: 0,
        viewCleaningCompany: 0,
        viewCleaner: 0
      },
      currentLang: getLanguage(),
      from: moment().subtract(7, "days").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      drawerOpen: false,
      filtersCount: 0,
      accessLevel: 0
    };

    moment.locale(this.state.currentLang);

    var decoded = Auth.getProfile();

    this.state.profileId = decoded.profileId;
    this.state.accessLevel = decoded.accessLevel;
    this.state.userName = decoded.userName;

    this.toggleDrawer = this.toggleDrawer.bind(this);

    var caViewConfig = JSON.parse(localStorage.getItem("caViewConfig"));
    if (caViewConfig) {
      Object.keys(caViewConfig).forEach(key => {
        this.state.filters[key] = caViewConfig[key];
      });
    }
  }

  async componentDidMount() {
    let overview = await this.loadLaundryOverview();
    let properties = await this.fetchProperties();
    let buildings = await this.fetchBuildings();
    let companies = await this.fetchCompanies();
    let cleaners = await this.fetchCleaners();

    this.setState({
      propertiesList: properties,
      buildingsList: buildings,
      laundryOverviewList: overview.data,
      isLoading: false,
      filtersCount: this.countFilters(),
      cleanersList: cleaners,
      companiesList: companies
    });
  }

  countFilters() {
    let count = 0;
    Object.keys(this.state.filters).forEach(key => {
      if (key !== "viewDate") {
        if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
          count++;
        }
      }
    });

    return count;
  }

  async loadLaundryOverview() {
    var self = this;
    return new Promise(function (resolve, reject) {
      fetchClient
        .post("ca/laundry-overview", {
          from: self.state.from,
          to: self.state.to,
          ...self.state.filters
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async refetchOverview() {
    let overview = await this.loadLaundryOverview();

    this.setState({
      laundryOverviewList: overview.data,
      isLoading: false,
      filtersCount: this.countFilters()
    });
  }

  async fetchProperties() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/properties")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchBuildings() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("buildings")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCompanies() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/companies")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCleaners() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/cleaners")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  handleDateChange(date, field) {
    this.setState(
      {
        [field]: date ? moment(date).format("YYYY-MM-DD") : null
      },
      () => {
        this.refetchOverview();
      }
    );
  }

  toggleDrawer = open => () => {
    this.setState({
      drawerOpen: open
    });
  };

  handleSelect = (val, event) => {
    this.setState(
      {
        isLoading: true,
        filters: {
          ...this.state.filters,
          [event.name]: val ? val.value : null
        },
        drawerOpen: false
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.refetchOverview();
      }
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, t } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    const locale = localeMap[this.state.currentLang];

    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.navigate("/");
              }}
              className={classes.menuButton}
              color="inherit"
              aria-label={t("misc.Back")}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h6" color="inherit" className={classes.grow}>
              {!this.state.isLoading ? t("titles.LaundryOverview") : t("titles.Loading") + "..."}
            </Typography>

            <div className={classes.sectionDesktop}>
              <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.toggleDrawer(true)} color="inherit">
                <Badge badgeContent={this.state.filtersCount} color="secondary">
                  <FilterList />
                </Badge>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.state.isLoading && <LinearProgress color="secondary" />}
        <div>
          {!this.state.isLoading ? (
            <div>
              <div className={classes.dateBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      fullWidth
                      label={t("misc.From")}
                      locale={locale}
                      format="DD/MM/YYYY"
                      autoOk={true}
                      name="from"
                      value={this.state.from}
                      onChange={e => this.handleDateChange(e, "from")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker fullWidth label={t("misc.To")} locale={locale} format="DD/MM/YYYY" autoOk={true} name="to" value={this.state.to} onChange={e => this.handleDateChange(e, "to")} />
                  </Grid>
                </Grid>
              </div>

              {Object.entries(this.state.laundryOverviewList).map(([key, value]) => {
                return (
                  <div key={key}>
                    <Typography variant="h6" color="inherit" className={classes.date}>
                      {moment(key).format("dddd DD/MM/YY")} - {value.length}
                    </Typography>
                    {value.map(note => {
                      return (
                        <Accordion key={note.noteId}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>
                              <span className="dot" /> {note.propertyName} - {note.laundryNote.substring(0, 50)}
                              ...
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={classes.details}>
                            <Typography>
                              <Note
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("laundry.Note")}:</b> {note.laundryNote}
                              <br />
                            </Typography>
                            <Typography>
                              <Person
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("laundry.CreatedBy")}:</b> {note.createdByName}
                              <br />
                            </Typography>

                            <Typography>
                              <DateRange
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("laundry.CreatedAt")}:</b> {moment(note.dateCreated).format("DD/MM/YYYY HH:mm")}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
          <div tabIndex={0} role="button" className={classes.drawer}>
            <div className={classes.drawerInput}>
              <Typography variant="h5" color="inherit">
                {t("list.Filters")}
              </Typography>
              <Typography variant="h6" color="inherit">
                {t("list.Property")}
              </Typography>

              <Select
                isClearable
                name="viewProperty"
                options={this.state.propertiesList.map(option => ({
                  value: option.propertyId,
                  label: option.propertyName
                }))}
                defaultValue={this.state.filters.viewProperty}
                classNamePrefix="react-select"
                onChange={this.handleSelect}
              />
            </div>

            <div className={classes.drawerInput}>
              <Typography variant="h6" color="inherit">
                {t("list.Building")}
              </Typography>

              <Select
                isClearable
                name="viewBuilding"
                options={this.state.buildingsList.map(option => ({
                  value: option.buildingId,
                  label: option.buildingName
                }))}
                defaultValue={this.state.filters.viewBuilding}
                classNamePrefix="react-select"
                onChange={this.handleSelect}
              />
            </div>
            {this.state.accessLevel > 2 && (
              <>
                <div className={classes.drawerInput}>
                  <Typography variant="h6" color="inherit">
                    {t("list.CleaningCompany")}
                  </Typography>

                  <Select
                    isClearable
                    name="viewCleaningCompany"
                    options={this.state.companiesList.map(option => ({
                      value: option.companyId,
                      label: option.companyName
                    }))}
                    defaultValue={this.state.filters.viewCleaningCompany}
                    classNamePrefix="react-select"
                    onChange={this.handleSelect}
                  />
                </div>
              </>
            )}

            {this.state.accessLevel > 1 && (
              <div className={classes.drawerInput}>
                <Typography variant="h6" color="inherit">
                  {t("list.Cleaner")}
                </Typography>

                <Select
                  isClearable
                  name="viewCleaner"
                  options={this.state.cleanersList.map(option => ({
                    value: option.cleanerId,
                    label: option.cleanerName
                  }))}
                  defaultValue={this.state.filters.viewCleaner}
                  classNamePrefix="react-select"
                  onChange={this.handleSelect}
                />
              </div>
            )}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

LaundryOverviewList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withStyles(styles)(LaundryOverviewList)));
