import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Assignment from "@mui/icons-material/Assignment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Person from "@mui/icons-material/Person";
import Bed from "@mui/icons-material/AirlineSeatIndividualSuite";
import IconButton from "@mui/material/IconButton";
import Note from "@mui/icons-material/Note";
import ArrowForward from "@mui/icons-material/ArrowForward";
import People from "@mui/icons-material/People";
import Place from "@mui/icons-material/Place";
import KingBed from "@mui/icons-material/KingBed";
import SingleBed from "@mui/icons-material/SingleBed";
import EventSeat from "@mui/icons-material/EventSeat";
import BathTub from "@mui/icons-material/Bathtub";
import AccessTime from "@mui/icons-material/AccessTime";
import LinearProgress from "@mui/material/LinearProgress";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ReactSelect from "react-select";
import FilterList from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@material-ui/pickers";

import moment from "moment";
import "moment/locale/es";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation, getLanguage } from "react-multi-lang";

import AuthService from "../../services/auth/AuthService";

import fetchClient from "../../utils/fetchClient";
import { withRouter } from "../../hocs/withRouter";

const Auth = new AuthService();

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    flexDirection: "column"
  },
  root: {
    width: "100%"
  },
  date: {
    marginTop: 10,
    padding: 15,
    color: "#4e5e9e"
  },
  dateBox: {
    margin: theme.spacing(1)
  },
  drawer: {
    width: 250
  },
  drawerInput: {
    margin: theme.spacing(1)
  }
});

function Select({ defaultValue, options, ...otherProps }) {
  return (
    <ReactSelect
      defaultValue={
        defaultValue && {
          value: defaultValue,
          label: options.find(o => o.value === defaultValue)?.label || "No Label"
        }
      }
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#70abdb",
          primary50: "#5b9fd6",
          primary75: "#4793d1",
          primary: "#3388cc"
        }
      })}
      options={options}
      {...otherProps}
    />
  );
}

const localeMap = {
  en: "en",
  es: "es"
};

class CleaningOverviewList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      cleaningOverviewList: [],
      propertiesList: [],
      buildingsList: [],
      companiesList: [],
      cleanersList: [],
      filters: {
        viewProperty: 0,
        viewBuilding: 0,
        viewCleaningCompany: 0,
        viewCleaner: 0
      },
      currentLang: getLanguage(),
      from: moment(),
      to: moment().add(7, "days").format("YYYY-MM-DD"),
      drawerOpen: false,
      filtersCount: 0,
      accessLevel: 0
    };

    moment.locale(this.state.currentLang);

    var decoded = Auth.getProfile();

    this.state.profileId = decoded.profileId;
    this.state.accessLevel = decoded.accessLevel;
    this.state.userName = decoded.userName;

    this.toggleDrawer = this.toggleDrawer.bind(this);

    var caViewConfig = JSON.parse(localStorage.getItem("caViewConfig"));
    if (caViewConfig) {
      Object.keys(caViewConfig).forEach(key => {
        this.state.filters[key] = caViewConfig[key];
      });
    }
  }

  async componentDidMount() {
    let overview = await this.loadOverview();
    let properties = await this.fetchProperties();
    let buildings = await this.fetchBuildings();
    let companies = await this.fetchCompanies();
    let cleaners = await this.fetchCleaners();

    this.setState({
      propertiesList: properties,
      buildingsList: buildings,
      cleaningOverviewList: overview.data,
      isLoading: false,
      filtersCount: this.countFilters(),
      cleanersList: cleaners,
      companiesList: companies
    });
  }

  countFilters() {
    let count = 0;
    Object.keys(this.state.filters).forEach(key => {
      if (key !== "viewDate") {
        if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
          count++;
        }
      }
    });

    return count;
  }

  async loadOverview() {
    var self = this;

    return new Promise(function (resolve, reject) {
      fetchClient
        .post("ca/cleaning-overview", {
          from: self.state.from,
          to: self.state.to,
          ...self.state.filters
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async refetchOverview() {
    let overview = await this.loadOverview();

    this.setState({
      cleaningOverviewList: overview.data,
      isLoading: false,
      filtersCount: this.countFilters()
    });
  }

  async fetchProperties() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/properties")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchBuildings() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("buildings")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCompanies() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/companies")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCleaners() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/cleaners")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  handleDateChange(date, field) {
    this.setState(
      {
        [field]: date ? moment(date).format("YYYY-MM-DD") : null
      },
      () => {
        this.refetchOverview();
      }
    );
  }

  toggleDrawer = open => () => {
    this.setState({
      drawerOpen: open
    });
  };

  handleSelect = (val, event) => {
    this.setState(
      {
        isLoading: true,
        filters: {
          ...this.state.filters,
          [event.name]: val ? val.value : null
        },
        drawerOpen: false
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.refetchOverview();
      }
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, t } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    const locale = localeMap[this.state.currentLang];

    const cleaningNames = {
      1: "Final",
      2: "Limpieza cliente completa",
      3: "Limpieza cliente completa (sc)",
      4: "Cambio ropa cama + toallas (sc)",
      5: "Cambio ropa cama + toallas",
      6: "Inicial (lanzamiento)",
      7: "Limpieza por obra",
      8: "Limpieza a fondo",
      9: "Limpieza cliente sin cambio de ropa"
    };

    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.navigate("/");
              }}
              className={classes.menuButton}
              color="inherit"
              aria-label={t("misc.Back")}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h6" color="inherit" className={classes.grow}>
              {!this.state.isLoading ? t("titles.CleaningOverview") : t("titles.Loading") + "..."}
            </Typography>

            <div className={classes.sectionDesktop}>
              <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.toggleDrawer(true)} color="inherit">
                <Badge badgeContent={this.state.filtersCount} color="secondary">
                  <FilterList />
                </Badge>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.state.isLoading && <LinearProgress color="secondary" />}
        <div>
          {!this.state.isLoading ? (
            <div>
              <div className={classes.dateBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      fullWidth
                      label={t("misc.From")}
                      locale={locale}
                      format="DD/MM/YYYY"
                      autoOk={true}
                      name="from"
                      value={this.state.from}
                      onChange={e => this.handleDateChange(e, "from")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker fullWidth label={t("misc.To")} locale={locale} format="DD/MM/YYYY" autoOk={true} name="to" value={this.state.to} onChange={e => this.handleDateChange(e, "to")} />
                  </Grid>
                </Grid>
              </div>

              {Object.entries(this.state.cleaningOverviewList).map(([key, value, index]) => {
                return (
                  <div key={key}>
                    <Typography variant="h6" color="inherit" className={classes.date}>
                      {moment(key).format("dddd DD/MM/YY")} - {value.length}
                    </Typography>
                    {value.map((cleaning, index) => {
                      let bedConfig = "";

                      bedConfig += cleaning.booking.numDoubleBeds > 0 ? cleaning.booking.numDoubleBeds + " " + t("list.BedDouble") + ", " : "";
                      bedConfig += cleaning.booking.numSingleBeds > 0 ? cleaning.booking.numSingleBeds + " " + t("list.BedSingle") + ", " : "";
                      bedConfig += cleaning.booking.numSofaBeds > 0 ? cleaning.booking.numSofaBeds + " " + t("list.BedSofa") + ", " : "";
                      bedConfig += cleaning.booking.numBabyCot > 0 ? cleaning.booking.numBabyCot + " " + t("list.BedBaby") + ", " : "";
                      bedConfig += cleaning.booking.numHighChairs > 0 ? cleaning.booking.numHighChairs + " " + t("list.HighChair") + " " : "";

                      bedConfig = bedConfig.replace(/,\s*$/, "");

                      return (
                        <Accordion key={index}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                              className={classes.heading}
                              style={
                                cleaning.statusId === "1"
                                  ? {
                                      textDecoration: "line-through",
                                      color: "grey"
                                    }
                                  : null
                              }
                            >
                              <span className={"dot dot-" + cleaningNames[cleaning.typeId]} /> {moment(cleaning.cleaningTime).format("HH:mm")} <b>{cleaning.property.propertyName} </b>{" "}
                              {cleaning.booking.numNights > 30 ? <FontAwesomeIcon icon="clock" /> : null}{" "}
                              {cleaning.property.propertyNotes ? <FontAwesomeIcon style={{ color: "blue" }} icon="info-circle" /> : null}{" "}
                              {cleaning.cleaningNotes || cleaning.cleaningDepartureNotes || cleaning.booking.numBabyCot > 0 || cleaning.booking.numHighChairs > 0 ? (
                                <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-triangle" />
                              ) : null}{" "}
                              <i>{cleaningNames[cleaning.typeId]}</i>
                              {this.state.accessLevel > 0 && cleaning.booking.nextIn ? (
                                <span>
                                  {" - "}
                                  <i>
                                    {t("list.NextIn")} {moment(cleaning.booking.nextIn).format("DD/MM HH:mm")}
                                  </i>
                                </span>
                              ) : null}{" "}
                              {cleaning.booking.numAdults ? (
                                <span>
                                  {" - "}
                                  <i>
                                    {cleaning.booking.numAdults}, {cleaning.booking.numChildren}, {cleaning.booking.numInfants}
                                  </i>
                                </span>
                              ) : null}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={classes.details}>
                            <Typography>
                              <Person
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.Cleaner")}:</b> {cleaning.cleaners.length ? cleaning.cleaners.join(", ") : <i>{t("list.NotAssigned")}</i>}
                              <br />
                            </Typography>

                            {this.state.accessLevel > 0 && (
                              <Typography>
                                <AccessTime
                                  style={{
                                    fontSize: 15
                                  }}
                                />{" "}
                                {cleaning.typeId === 1 ? <b>{t("list.Out")}:</b> : <b>{t("list.Hour")}:</b>} {moment(cleaning.cleaningTime).format("HH:mm")} <br />
                              </Typography>
                            )}

                            {this.state.accessLevel > 0 && (
                              <Typography>
                                {cleaning.booking.nextIn ? (
                                  <span>
                                    <ArrowForward
                                      style={{
                                        fontSize: 15
                                      }}
                                    />{" "}
                                    <b>{t("list.NextIn")}: </b> {moment(cleaning.booking.nextIn).format("DD/MM HH:mm")}
                                  </span>
                                ) : null}
                              </Typography>
                            )}
                            <Typography>
                              <Assignment
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.Scheduled")}:</b> {cleaning.cleaningTimeScheduled ? moment(cleaning.cleaningTimeScheduled).format("HH:mm") : <i>{t("list.NoTimeScheduled")}</i>} <br />
                            </Typography>
                            <Typography>
                              {cleaning.booking.numAdults ? (
                                <span>
                                  <People
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.Pax")}:</b> {cleaning.booking.numAdults}, {cleaning.booking.numChildren}, {cleaning.booking.numInfants}
                                </span>
                              ) : null}
                            </Typography>
                            <Typography>
                              {bedConfig.length ? (
                                <span>
                                  <Bed
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.BedConfig")}:</b> {bedConfig}
                                </span>
                              ) : null}
                            </Typography>
                            <Typography>
                              <Place
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.Address")}:</b>{" "}
                              <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(cleaning.property.propertyAddress)}>
                                {cleaning.property.propertyAddress}, {cleaning.property.propertyFloor} - {cleaning.property.propertyDoor}
                              </a>
                              <br />
                            </Typography>

                            <Typography>
                              {cleaning.cleaningNotes ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.CleaningNotes")}:</b> {cleaning.cleaningNotes}
                                </span>
                              ) : null}
                            </Typography>

                            <Typography>
                              {cleaning.cleaningDepartureNotes ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.CleaningDepartureNotes")}:</b> {cleaning.CleaningDepartureNotes}
                                </span>
                              ) : null}
                            </Typography>

                            {this.state.accessLevel > 0 && (
                              <Typography>
                                {cleaning.property.propertyNotes ? (
                                  <span>
                                    <Note
                                      style={{
                                        fontSize: 15
                                      }}
                                    />{" "}
                                    <b>{t("list.PropertyNotes")}:</b> {cleaning.property.propertyNotes}
                                  </span>
                                ) : null}
                              </Typography>
                            )}

                            <Typography>
                              {cleaning.property.propertyCleaningCompany ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.CleaningCompany")}:</b> {cleaning.property.propertyCleaningCompany}
                                </span>
                              ) : null}
                            </Typography>
                            <Typography>
                              {cleaning.property.propertyLaundryCompany ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.LaundryCompany")}:</b> {cleaning.property.propertyLaundryCompany}
                                </span>
                              ) : null}
                            </Typography>

                            <Typography>
                              {cleaning.property.propertyLaundryOwner ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.LaundryOwner")}:</b> {cleaning.property.propertyLaundryOwner}
                                </span>
                              ) : null}
                            </Typography>

                            {this.state.accessLevel === 0 && (
                              <>
                                <Typography>
                                  {cleaning.property.propertyNumDoubleBeds ? (
                                    <span>
                                      <KingBed
                                        style={{
                                          fontSize: 15
                                        }}
                                      />{" "}
                                      <b>{t("list.DoubleBed")}:</b> {cleaning.property.propertyNumDoubleBeds}
                                    </span>
                                  ) : null}
                                </Typography>

                                <Typography>
                                  {cleaning.property.propertyNumSingleBeds ? (
                                    <span>
                                      <SingleBed
                                        style={{
                                          fontSize: 17
                                        }}
                                      />{" "}
                                      <b>{t("list.SingleBed")}:</b> {cleaning.property.propertyNumSingleBeds}
                                    </span>
                                  ) : null}
                                </Typography>

                                <Typography>
                                  {cleaning.property.propertyNumSingleSofaBeds ? (
                                    <span>
                                      <EventSeat
                                        style={{
                                          fontSize: 15
                                        }}
                                      />{" "}
                                      <b>{t("list.DoubleSofa")}:</b> {cleaning.property.propertyNumSingleSofaBeds}
                                    </span>
                                  ) : null}
                                </Typography>

                                <Typography>
                                  {cleaning.property.propertyNumDoubleSofaBeds ? (
                                    <span>
                                      <EventSeat
                                        style={{
                                          fontSize: 15
                                        }}
                                      />{" "}
                                      <b>{t("list.SingleSofa")}:</b> {cleaning.property.propertyNumDoubleSofaBeds}
                                    </span>
                                  ) : null}
                                </Typography>

                                <Typography>
                                  {cleaning.property.propertyNumberBathrooms ? (
                                    <span>
                                      <BathTub
                                        style={{
                                          fontSize: 15
                                        }}
                                      />{" "}
                                      <b>{t("list.Bathrooms")}:</b> {cleaning.property.propertyNumberBathrooms}
                                    </span>
                                  ) : null}
                                </Typography>
                              </>
                            )}

                            <Typography>
                              {cleaning.typeId === "2" && cleaning.booking.hours ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.Hours")}:</b> {cleaning.booking.hours}
                                </span>
                              ) : null}
                            </Typography>

                            <Typography>
                              {cleaning.typeId === "2" ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.Towels")}:</b> {cleaning.booking.towels === 1 ? t("list.Yes") : t("list.No")}
                                </span>
                              ) : null}
                            </Typography>

                            <Typography>
                              {cleaning.typeId === "2" ? (
                                <span>
                                  <Note
                                    style={{
                                      fontSize: 15
                                    }}
                                  />{" "}
                                  <b>{t("list.BedLinen")}:</b> {cleaning.booking.bedlinen === 1 ? t("list.Yes") : t("list.No")}
                                </span>
                              ) : null}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
          <div tabIndex={0} role="button" className={classes.drawer}>
            <div className={classes.drawerInput}>
              <Typography variant="h5" color="inherit">
                {t("list.Filters")}
              </Typography>
              <Typography variant="h6" color="inherit">
                {t("list.Property")}
              </Typography>

              <Select
                isClearable
                name="viewProperty"
                options={this.state.propertiesList.map(option => ({
                  value: option.propertyId,
                  label: option.propertyName
                }))}
                defaultValue={this.state.filters.viewProperty}
                classNamePrefix="react-select"
                onChange={this.handleSelect}
              />
            </div>

            <div className={classes.drawerInput}>
              <Typography variant="h6" color="inherit">
                {t("list.Building")}
              </Typography>

              <Select
                isClearable
                name="viewBuilding"
                options={this.state.buildingsList.map(option => ({
                  value: option.buildingId,
                  label: option.buildingName
                }))}
                defaultValue={this.state.filters.viewBuilding}
                classNamePrefix="react-select"
                onChange={this.handleSelect}
              />
            </div>
            {this.state.accessLevel > 2 && (
              <>
                <div className={classes.drawerInput}>
                  <Typography variant="h6" color="inherit">
                    {t("list.CleaningCompany")}
                  </Typography>

                  <Select
                    isClearable
                    name="viewCleaningCompany"
                    options={this.state.companiesList.map(option => ({
                      value: option.companyId,
                      label: option.companyName
                    }))}
                    defaultValue={this.state.filters.viewCleaningCompany}
                    classNamePrefix="react-select"
                    onChange={this.handleSelect}
                  />
                </div>
              </>
            )}

            {this.state.accessLevel > 1 && (
              <div className={classes.drawerInput}>
                <Typography variant="h6" color="inherit">
                  {t("list.Cleaner")}
                </Typography>

                <Select
                  isClearable
                  name="viewCleaner"
                  options={this.state.cleanersList.map(option => ({
                    value: option.cleanerId,
                    label: option.cleanerName
                  }))}
                  defaultValue={this.state.filters.viewCleaner}
                  classNamePrefix="react-select"
                  onChange={this.handleSelect}
                />
              </div>
            )}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

CleaningOverviewList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withStyles(styles)(CleaningOverviewList)));
