import React from "react";

// ** Third party components
import { useTranslation } from "react-multi-lang";
import { useImageUploader } from "../hooks/useImageUploader";
import { DragDrop, StatusBar } from "@uppy/react";

// ** Utils
import fetchClient from "../utils/fetchClient";

export const ImageUploader = ({ bookingId, type, cleaningsList, updateList, image: initialImage, cleaningId }) => {
  // ** Hooks
  const t = useTranslation();
  const { image, imageUploader } = useImageUploader({
    id: "ca" + bookingId + type,
    autoProceed: true,
    restrictions: {
      allowMultipleUploads: false,
      allowedFileTypes: ["image/*"]
    },
    destination: "cleaning_proofs",
    upload_type: "cleaning_proofs",
    initialImage,
    onSuccessUpload: (_, __, newImage) => {
      fetchClient
        .post("/ca/save-proof/" + bookingId, {
          image: newImage,
          type: type
        })
        .then(function (response) {
          let updatedList = cleaningsList.filter(cleaning => {
            if (cleaning.cleaningId === cleaningId) {
              if (type === 1) {
                cleaning.booking.proofs.sofaBed = newImage;
              } else if (type === 2) {
                cleaning.booking.proofs.babyCot = newImage;
              } else if (type === 3) {
                cleaning.booking.proofs.keyPrep = newImage;
              }

              return cleaning;
            }
            return cleaning;
          });

          updateList(updatedList);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });

  // ** States

  return (
    !!imageUploader && (
      <div>
        <DragDrop
          uppy={imageUploader}
          locale={{
            strings: {
              dropHereOr: t("list.Upload") + " %{browse}",
              browse: t("list.Browse")
            }
          }}
        />
        <StatusBar uppy={imageUploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

        <div style={{ paddingBottom: 10 }}>
          {image && (
            <img
              style={{
                paddingLeft: 2,
                paddingRight: 2
              }}
              height="50"
              width="50"
              className="uploaded-image"
              src={image}
              alt={image.split("/").pop()}
            />
          )}
        </div>
      </div>
    )
  );
};
