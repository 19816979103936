import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";

// ** Router
import { Router } from "./routes";
import { AuthProvider } from "./contexts/auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

const theme = createTheme({
  palette: {
    primary: { main: "#296da3", contrastText: "#ffffff" },
    secondary: { main: "#77D6ED", contrastText: "#000000" }
  }
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <HashRouter>
            <Router />
          </HashRouter>
        </AuthProvider>
      </ThemeProvider>
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
