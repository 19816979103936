import { useState, useEffect } from "react";

// ** Third party components
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ImageCompressor from "uppy-plugin-image-compressor";

// ** Utils
import fetchClient from "../utils/fetchClient";

export const useImageUploader = ({ destination, upload_type, onSuccessUpload, initialImage = null, ...restOptions }) => {
  // ** States
  const [image, setImage] = useState(initialImage);
  const [imageUploader, setImageUploader] = useState(null);

  useEffect(() => {
    const newImageUploader = new Uppy(restOptions)
      .use(ImageCompressor, {
        maxWidth: 1000,
        quality: 0.6
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          return fetchClient
            .post(
              "upload",
              JSON.stringify({
                destination,
                upload_type,
                filename: file.name,
                contentType: file.type
              })
            )
            .then(response => {
              return response.data;
            })
            .then(data => {
              return {
                method: data.method,
                url: data.url,
                fields: data.fields,
                resultId: data.resultId
              };
            });
        }
      })
      .on("upload-success", (file, resp) => {
        const newImage = resp.uploadURL;

        onSuccessUpload(file, resp, newImage);

        setImage(newImage);
      });

    setImageUploader(newImageUploader);

    return () => {
      if (imageUploader) imageUploader.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { image, imageUploader };
};
