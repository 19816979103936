import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Button, TextField, FormControl, LinearProgress, FormControlLabel, Switch } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-multi-lang";
import fetchClient from "../utils/fetchClient";
import AuthService from "../services/auth/AuthService";
import moment from "moment";
import { DragDrop, StatusBar } from "@uppy/react";
import { useImageUploader } from "../hooks/useImageUploader";

import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

const Auth = new AuthService();

const LostAndFound = () => {
  // ** States
  const [itemsList, setItemsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showAll, setShowAll] = useState(true);
  const [property, setProperty] = useState({});
  const [newItem, setNewItem] = useState({
    itemDesc: "",
    propertyId: "",
    images: []
  });

  // ** Hooks
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { imageUploader } = useImageUploader({
    id: "lostfound",
    autoProceed: true,
    destination: "lostfound",
    upload_type: "lostfound",
    onSuccessUpload: (_, __, newImage) => {
      setNewItem({ ...newItem, images: [...newItem.images, newImage] });
    }
  });

  const { userId } = Auth.getProfile();

  const handleChange = event => {
    setNewItem({ ...newItem, itemDesc: event.target?.value });
    checkForErrors();
  };

  const handleToggle = event => {
    setLoading(true);
    setShowAll(event.target.checked);
    refetchItems();
  };

  //   const handleSelect = val => {
  //     setNewItem(prev => ({ ...prev, propertyId: val ? val.value : null }));
  //     checkForErrors();
  //   };

  const submitItem = () => {
    setIsDisabled(true);
    setNewItem({ itemDesc: "", images: [] });

    fetchClient
      .post("/ca/lostfound/add/" + params.id, {
        ...newItem,
        propertyId: params.id
      })
      .then(function (response) {
        refetchItems();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const initialize = async () => {
    let tasks = await fetchItems();

    setProperty(tasks.data.property);
    setItemsList(tasks.data.items.reverse());
    setLoading(false);
  };

  useEffect(() => {
    if (imageUploader) initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploader]);

  const refetchItems = async () => {
    let tasks = await fetchItems();

    setItemsList(tasks.data.items.reverse());
    setLoading(false);
  };

  const fetchItems = async () => {
    return new Promise(function (resolve, reject) {
      fetchClient
        .post("/ca/lostfound/view/" + params.id, {
          viewCreator: showAll ? 0 : userId
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const checkForErrors = () => {
    let errorCount = 0;

    if (!newItem.itemDesc) errorCount++;

    setIsDisabled(errorCount > 0);
  };

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
            style={{ marginLeft: -12, marginRight: 20 }}
            color="inherit"
            aria-label={t("misc.Back")}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {!loading ? t("titles.LostFound") : t("titles.Loading") + "..."}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress color="secondary" />}

      <div style={{ padding: "1em" }}>
        {!loading ? (
          <div>
            <Typography variant="h6">
              {[3, 23, 19, 9].includes(property.buildingId) ? t("lostfound.NoticeBuildings") : property.autoCheckin === 1 ? t("lostfound.NoticeAuto") : t("lostfound.NoticeNoAuto")}
            </Typography>
            <FormControl fullWidth style={{ marginTop: "1em", marginBottom: "1em" }}>
              <TextField
                required
                label={t("lostfound.ItemDesc")}
                multiline
                style={{ marginTop: "1em", marginBottom: "1em" }}
                onChange={handleChange}
                name="itemDesc"
                InputLabelProps={{
                  shrink: true
                }}
                value={newItem.itemDesc}
                error={!Boolean(newItem.itemDesc)}
              />
            </FormControl>

            <DragDrop
              uppy={imageUploader}
              locale={{
                strings: {
                  dropHereOr: t("lostfound.Upload") + " %{browse}",
                  browse: t("lostfound.Browse")
                }
              }}
            />
            <StatusBar uppy={imageUploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
            <div>
              {newItem.images.map((img, index) => {
                return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
              })}
            </div>
            <Button type="submit" style={{ marginTop: 10 }} variant="contained" color="primary" disabled={isDisabled} onClick={submitItem}>
              {t("lostfound.Add")}
            </Button>
            <br />
            <br />
            <FormControlLabel control={<Switch checked={showAll} onChange={handleToggle} value="1" />} label={t("lostfound.ShowAll")} />
            <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
              {showAll ? t("lostfound.AllItems") : t("lostfound.MyItems")}
            </Typography>
            {itemsList && itemsList.length > 0 ? (
              itemsList.map((item, index) => {
                return (
                  <div key={index}>
                    <p
                      style={{
                        textDecoration: item.itemDelivered === 1 ? "line-through" : null
                      }}
                      key={item.itemId}
                    >
                      {moment(item.createdDate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {item.itemDesc}
                    </p>
                    {item.images.map((image, index) => {
                      return (
                        <img
                          style={{
                            paddingLeft: 2,
                            paddingRight: 2
                          }}
                          height="50"
                          width="50"
                          className="uploaded-image"
                          key={index}
                          src={image}
                          alt={image.split("/").pop()}
                        />
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <p> {t("lostfound.NoItems")}</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LostAndFound;
