import React from "react";
import PropTypes from "prop-types";

// ** Material UI
import {
  Typography,
  FormLabel,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  LinearProgress,
  SwipeableDrawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { withStyles } from "@mui/styles";

// ** Material UI Icons
import Assignment from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Bathtub from "@mui/icons-material/Bathtub";
import Person from "@mui/icons-material/Person";
import Bed from "@mui/icons-material/AirlineSeatIndividualSuite";
import Note from "@mui/icons-material/Note";
import KingBed from "@mui/icons-material/KingBed";
import SingleBed from "@mui/icons-material/SingleBed";
import EventSeat from "@mui/icons-material/EventSeat";
import BathTub from "@mui/icons-material/Bathtub";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import AccessTime from "@mui/icons-material/AccessTime";
import People from "@mui/icons-material/People";
import Place from "@mui/icons-material/Place";

// ** Third party components
import moment from "moment";
import { saveAs } from "file-saver";
import { withSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation, getLanguage } from "react-multi-lang";
import { withRouter } from "../../hocs/withRouter";

// ** Custom components
import { ImageUploader } from "../../components/ImageUploader";
import { FiltersDrawer } from "../../components/FiltersDrawer";
import { CleaningMenu } from "../../components/cleaning/list/CleaningMenu";
import { CleaningAppBar } from "../../components/cleaning/list/CleaningAppBar";

// ** Assets
import dishwasher from "../../dishwasher.png";
import amenities from "../../amenities.png";

// ** Utils
import AuthService from "../../services/auth/AuthService";
import fetchClient from "../../utils/fetchClient";

// ** Styles
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

const Auth = new AuthService();

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  datePicker: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    width: 90
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  headerIcon: {
    marginRight: 5
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    flexDirection: "column"
  },
  flex: {
    flex: 1
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 300
  },
  root: {
    width: "100%"
  },
  appBar: {
    position: "relative"
  },
  button: {
    marginTop: 10
  },
  drawer: {
    width: 250
  },
  drawerInput: {
    margin: theme.spacing(1)
  },
  proofs: {
    borderStyle: "solid",
    borderColor: "red",
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
    color: "red"
  },
  proofsDone: {
    borderStyle: "solid",
    borderColor: "green",
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
    color: "green"
  },
  secondaryBar: {
    backgroundColor: "rgb(232, 232, 232)"
  }
});

class CleaningsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isDisabled: true,
      cleaningsList: [],
      propertiesList: [],
      buildingsList: [],
      companiesList: [],
      cleanersList: [],
      filters: {
        viewDate: moment().format("YYYY-MM-DD"),
        viewProperty: 0,
        viewBuilding: 0,
        viewCleaningCompany: 0
      },
      submitEnabled: true,
      front_loading: false,
      apt_loading: false,
      drawerOpen: false,
      filtersCount: 0,
      pressCount: { 1: { count: 0 }, 2: { count: 0 } },
      currentLang: getLanguage(),
      checkoutImpossibleReason: "",
      impossibleDialogOpen: false,
      actionId: null,
      propertyId: null,
      standards: {
        standardsURL: null,
        updated: false
      }
    };

    moment.locale(this.state.currentLang);

    var decoded = Auth.getProfile();

    this.state.profileId = decoded.profileId;
    this.state.accessLevel = decoded.accessLevel;
    this.state.userName = decoded.userName;

    this.logout = this.logout.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.fetchCleanings = this.fetchCleanings.bind(this);
    this.goToToday = this.goToToday.bind(this);
    this.changeTaskStatus = this.changeTaskStatus.bind(this);
    this.handleOpenDoor = this.handleOpenDoor.bind(this);
    this.setLaundryDate = this.setLaundryDate.bind(this);
    this.startCheckout = this.startCheckout.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.updateList = this.updateList.bind(this);
    this.handleDateNext = this.handleDateNext.bind(this);
    this.handleDatePrev = this.handleDatePrev.bind(this);
    this.saveStandards = this.saveStandards.bind(this);

    var caViewConfig = JSON.parse(localStorage.getItem("caViewConfig"));
    if (caViewConfig) {
      Object.keys(caViewConfig).forEach(key => {
        this.state.filters[key] = caViewConfig[key];
      });
    }
  }

  openImpossibleDialog = cleaning => {
    this.setState({
      actionId: cleaning.cleaningId,
      propertyId: cleaning.property.propertyId,
      impossibleDialogOpen: true,
      checkoutImpossibleReason: ""
    });
  };

  closeImpossibleDialog = () => {
    this.setState({
      actionId: null,
      propertyId: null,
      impossibleDialogOpen: false,
      checkoutImpossibleReason: ""
    });
  };

  handleTextChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  submitReason = () => {
    fetchClient
      .post("/ca/report-impossible/add/" + this.state.actionId, {
        reason: this.state.checkoutImpossibleReason,
        date: this.state.filters.viewDate
      })
      .then(() => this.closeImpossibleDialog())
      .catch(function (error) {
        console.log(error);
      });

    const cleaning = this.state.cleaningsList.find(item => item.cleaningId === this.state.actionId);
    if (cleaning) {
      this.changeTaskStatus(1, { ...cleaning, checkoutImpossible: 1 });
    }
  };

  toggleDrawer = open => () => {
    this.setState({
      drawerOpen: open
    });

    if (!open) {
      this.handleMenuClose();
    }
  };

  updateList(list) {
    this.setState({ cleaningsList: list });
  }

  logout() {
    Auth.logout();
    this.props.navigate("/login");
  }

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDateNext() {
    var next = moment(this.state.filters.viewDate).add(1, "days").format("YYYY-MM-DD");

    this.setState({ isLoading: true, filters: { ...this.state.filters, viewDate: next } }, () => {
      localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
      this.refetchCleanings();
    });
  }

  handleDatePrev() {
    var prev = moment(this.state.filters.viewDate).subtract(1, "days").format("YYYY-MM-DD");

    this.setState({ isLoading: true, filters: { ...this.state.filters, viewDate: prev } }, () => {
      localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
      this.refetchCleanings();
    });
  }

  async componentDidMount() {
    this.interval = setInterval(() => this.refetchCleanings(), 20000);

    let properties = await this.fetchProperties();
    let buildings = await this.fetchBuildings();
    let companies = await this.fetchCompanies();
    let cleaners = await this.fetchCleaners();
    let cleanings = await this.fetchCleanings();

    this.setState({
      cleaningsList: cleanings.cleanings.filter(cleaning => {
        if (cleaning.booking.numInfants > 0) console.log(cleaning);

        return !cleaning.property.propertyName.includes("STBO");
      }), // TODO: Provisional solution. The correct way is with backend filter
      standards: cleanings.standards,
      propertiesList: properties,
      buildingsList: buildings,
      companiesList: companies,
      cleanersList: cleaners,
      filtersCount: this.countFilters(),
      isLoading: false
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  countFilters() {
    let count = 0;
    Object.keys(this.state.filters).forEach(key => {
      if (key !== "viewDate") {
        if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
          count++;
        }
      }
    });

    return count;
  }

  async refetchCleanings() {
    let cleanings = await this.fetchCleanings();

    this.setState({
      cleaningsList: cleanings.cleanings.filter(cleaning => !cleaning.property.propertyName.includes("STBO")), // TODO: Provisional solution. The correct way is with backend filter
      filtersCount: this.countFilters(),
      isLoading: false
    });
  }

  async fetchCleanings() {
    var self = this;
    return new Promise(function (resolve, reject) {
      fetchClient
        .post("ca/list", self.state.filters)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchProperties() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/properties")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchBuildings() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("buildings")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCompanies() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/companies")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async fetchCleaners() {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/cleaners")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  handleSelect = (val, event) => {
    this.setState(
      {
        isLoading: true,
        filters: {
          ...this.state.filters,
          [event.name]: val ? val.value : null
        },
        drawerOpen: false
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.handleMenuClose();
        this.refetchCleanings();
      }
    );
  };

  handleDateChange = date => {
    this.setState(
      {
        isLoading: true,
        filters: {
          ...this.state.filters,
          viewDate: date ? moment(date).format("YYYY-MM-DD") : null
        },
        drawerOpen: false
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.refetchCleanings();
      }
    );
  };

  changeTaskStatus(statusId, cleaning) {
    var self = this;
    let cleaningId = cleaning.cleaningId;

    if (cleaning.checkoutImpossible === 0 && statusId === 1 && cleaning.needsCheckout === 1 && cleaning.checkoutDone === 0) {
      self.openImpossibleDialog(cleaning);
      self.setState({
        cleaningsList: self.state.cleaningsList.filter(cleaning => {
          if (cleaning.cleaningId === cleaningId) {
            cleaning.checkoutImpossible = 1;
            return cleaning;
          }
          return cleaning;
        })
      });
      return false;
    }

    fetchClient
      .post("ca/status", {
        cleaningId,
        statusId
      })
      .then(function (response) {
        self.setState({
          cleaningsList: self.state.cleaningsList.filter(cleaning => {
            if (cleaning.cleaningId === cleaningId) {
              cleaning.statusId = statusId;
              return cleaning;
            }
            return cleaning;
          })
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setLaundryDate(event, id) {
    var self = this;

    let statusId = event.target.value;

    fetchClient
      .post("ca/laundry-status", {
        statusId: statusId,
        id: id,
        viewDate: self.state.filters.viewDate
      })
      .then(function (response) {
        self.setState({
          cleaningsList: self.state.cleaningsList.filter(cleaning => {
            if (cleaning.property.propertyId === id) {
              cleaning.property.propertyLaundryCameToday = statusId;
              return cleaning;
            }
            return cleaning;
          })
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleFilterChange = event => {
    this.setState(
      {
        isLoading: true,
        filters: { ...this.state.filters, viewDate: event.target.value }
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.refetchCleanings();
      }
    );
  };

  startCheckout(propertyId) {
    const url = "https://checkoutapp.stay-u-nique.com/#/checkout/" + propertyId;
    window.location = url;
  }

  goToToday() {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          viewDate: moment().format("YYYY-MM-DD")
        }
      },
      () => {
        localStorage.setItem("caViewConfig", JSON.stringify(this.state.filters));
        this.refetchCleanings();
      }
    );
  }

  handleOpenDoor(id, type, cleaning) {
    var self = this;

    let hour = cleaning.cleaningTime ? moment(cleaning.cleaningTime).format("HH:mm") : "11:00";
    let day = cleaning.cleaningTime ? moment(cleaning.cleaningTime).format("DD/MM") : moment().format("DD/MM");

    let errors = {
      1: self.props.t("list.UnlockBeforeTime", { hour: hour, day: day }),
      2: self.props.t("list.UnlockAfterTime")
    };

    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (self.state.pressCount[type].count === 1) {
      self.setState({
        submitEnabled: false,
        front_loading: type === 2 ? true : false,
        apt_loading: type === 1 ? true : false
      });
      fetchClient
        .post("ca/smartlock/" + id, { doortype: type, typeId: cleaning.typeId, cleaningTime: cleaning.cleaningTime })
        .then(function (response) {
          self.setState({
            front_loading: false,
            apt_loading: false,
            submitEnabled: true,
            pressCount: { 1: { count: 0 }, 2: { count: 0 } }
          });

          if (response.data.success) {
            self.props.enqueueSnackbar(self.props.t("list.LockOpened"), { variant: "success", preventDuplicate: true });
          } else {
            let error = self.props.t("list.LockError");
            if (errors[response.data.data.errorCode]) {
              error = errors[response.data.data.errorCode];
            }
            self.props.enqueueSnackbar(error, { variant: "error", preventDuplicate: true });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const newpressCount = self.state.pressCount;
      newpressCount[type].count = 1;

      self.setState({
        pressCount: newpressCount
      });
    }

    this.timer = setTimeout(() => {
      self.setState({
        pressCount: { 1: { count: 0 }, 2: { count: 0 } }
      });
    }, 3000);
  }

  saveStandards() {
    let file = this.state.standards.standardsURL;

    if (!file) {
      return;
    }

    const name = file.split("/").pop();
    saveAs(file, name);
  }

  render() {
    const { anchorEl } = this.state;
    const { classes, t } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    const colors = {
      0: "red",
      1: "green",
      2: "orange"
    };

    const cleaningNames = {
      1: { class: "final", label: "Final" },
      2: { class: "cliente", label: "Limpieza cliente completa" },
      3: { class: "cliente-sc", label: "Limpieza cliente completa (sc)" },
      4: { class: "ropa-cama-toallas", label: "Cambio ropa cama + toallas (sc)" },
      5: { class: "ropa-cama-toallas-sc", label: "Cambio ropa cama + toallas" },
      6: { class: "inicial", label: "Inicial (lanzamiento)" },
      7: { class: "obra", label: "Limpieza por obra" },
      8: { class: "fondo", label: "Limpieza a fondo" },
      9: { class: "cliente-no-cambio-ropa", label: "Limpieza cliente sin cambio de ropa" }
    };

    return (
      <div className={classes.root}>
        <CleaningAppBar
          isMenuOpen={isMenuOpen}
          goToToday={this.goToToday}
          saveStandards={this.saveStandards}
          state={this.state}
          toggleDrawer={this.toggleDrawer(true)}
          handleMenuOpen={this.handleMenuOpen}
          handleDatePrev={this.handleDatePrev}
          handleDateChange={this.handleDateChange}
          handleDateNext={this.handleDateNext}
          currentLang={this.state.currentLang}
          standards={this.state.standards}
          filtersCount={this.state.filtersCount}
          filters={this.state.filters}
        />

        <CleaningMenu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
          toggleDrawer={this.toggleDrawer(true)}
          logout={this.logout}
          filtersCount={this.state.filtersCount}
          userName={this.state.userName}
          accessLevel={this.state.accessLevel}
        />

        {this.state.isLoading && <LinearProgress color="secondary" />}

        <div>
          {!this.state.isLoading &&
            this.state.cleaningsList.map(cleaning => {
              let bedConfig = "";

              bedConfig += cleaning.booking.numDoubleBeds > 0 ? cleaning.booking.numDoubleBeds + " " + t("list.BedDouble") + ", " : "";
              bedConfig += cleaning.booking.numSingleBeds > 0 ? cleaning.booking.numSingleBeds + " " + t("list.BedSingle") + ", " : "";
              bedConfig += cleaning.booking.numSofaBeds > 0 ? cleaning.booking.numSofaBeds + " " + t("list.BedSofa") + ", " : "";
              bedConfig += cleaning.booking.numBabyCot > 0 ? cleaning.booking.numBabyCot + " " + t("list.BedBaby") + ", " : "";
              bedConfig += cleaning.booking.numHighChairs > 0 ? cleaning.booking.numHighChairs + " " + t("list.HighChair") + " " : "";

              bedConfig = bedConfig.replace(/,\s*$/, "");

              const hasBabyCot = cleaning.booking.numBabyCot && cleaning.booking.numBabyCot > 0;
              const thereAreInfants = cleaning.booking.numInfants && cleaning.booking.numInfants > 0;

              let needsBabyCot = (hasBabyCot || thereAreInfants) && cleaning.booking.proofs.babyCot === null;
              // let needsSofaBed = cleaning.booking.numSofaBeds && cleaning.booking.numSofaBeds > 0 && cleaning.booking.proofs.sofaBed === null ? true : false;

              let needsSofaBed =
                ((Number(cleaning.property.propertyNumDoubleSofaBeds) > 0 || Number(cleaning.property.propertyNumSingleSofaBeds) > 0) &&
                  (cleaning.booking.numAdults > 0 || cleaning.booking.numChildren > 0 || cleaning.booking.numInfants) &&
                  cleaning.booking.proofs.sofaBed === null) ||
                (cleaning.booking.numSofaBeds && cleaning.booking.numSofaBeds > 0 && cleaning.booking.proofs.sofaBed === null)
                  ? true
                  : false;

              const showSofaBedUploader =
                ((Number(cleaning.property.propertyNumDoubleSofaBeds) > 0 || Number(cleaning.property.propertyNumSingleSofaBeds) > 0) &&
                  (cleaning.booking.numAdults + cleaning.booking.numChildren > cleaning.property.propertyMinBeforeSofaBed )) ||
                (cleaning.booking.numSofaBeds && cleaning.booking.numSofaBeds > 0)
                  ? true
                  : false;

              const showSofaBedLinenUploader =
                ((Number(cleaning.property.propertyNumDoubleSofaBeds) > 0 || Number(cleaning.property.propertyNumSingleSofaBeds) > 0) &&
                (cleaning.booking.numAdults + cleaning.booking.numChildren <= cleaning.property.propertyMinBeforeSofaBed )) 
                  ? true
                  : false;

              // let needsKeyBoxCode = cleaning.property.checkinProcedureId === 34 && cleaning.booking.proofs.keyBoxCode === null ? true : false;

              return (
                <Accordion key={cleaning.cleaningId}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      className={classes.heading}
                      style={
                        cleaning.statusId === 1
                          ? {
                              textDecoration: "line-through",
                              color: "grey"
                            }
                          : null
                      }
                    >
                      {/* // TODO: Aplicar colores */}
                      <span className={"dot dot-" + cleaningNames[cleaning.typeId]?.class || ""} /> <span>{moment(cleaning.cleaningTime).format("HH:mm")} </span>
                      <b>{cleaning.property.propertyName} </b>{" "}
                      {cleaning.property.propertyPendingMaintenanceTasks > 0 ? (
                        <span style={{ color: "orange" }}>
                          <FontAwesomeIcon style={{ height: 15 }} icon="tools" /> {cleaning.property.propertyPendingMaintenanceTasks}
                        </span>
                      ) : null}{" "}
                      <FontAwesomeIcon style={{ color: colors[cleaning.statusId] }} icon="broom" /> {cleaning.booking.numNights > 30 ? <FontAwesomeIcon icon="clock" /> : null}{" "}
                      {this.state.accessLevel > 0 && <>{cleaning.property.propertyNotes ? <FontAwesomeIcon style={{ color: "blue" }} icon="info-circle" /> : null}</>}{" "}
                      {cleaning.cleaningNotes || cleaning.cleaningDepartureNotes || cleaning.booking.numBabyCot > 0 || cleaning.booking.numHighChairs > 0 ? (
                        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-triangle" />
                      ) : null}{" "}
                      {cleaning.hasCleaningReport === 1 ? <FontAwesomeIcon style={{ color: "purple" }} icon="clipboard-check" /> : null}{" "}
                      {cleaning.checkoutImpossible === 1 ? <FontAwesomeIcon style={{ color: "red" }} icon="exclamation" /> : null}{" "}
                      {cleaning.property.propertyManagement === 3 ? <FontAwesomeIcon icon="home" /> : null}{" "}
                      {(cleaning.booking.nextBookingChannelName === "Marriott Homes & Villas" || cleaning.booking.nextBookingChannelName === "Plum Guide")? <FontAwesomeIcon style={{ color: "red" }} icon="heart" /> : null}{" "}
                      {cleaning.property.hasDishwasher ? <img height="16" width="18" alt={t("list.Dishwasher")} style={{ paddingLeft: 3, verticalAlign: "-0.125em" }} src={dishwasher} /> : null}{" "}
                      <i>{cleaningNames[cleaning.typeId]?.label}</i>
                      <br />{" "}
                      {this.state.accessLevel > 0 && (
                        <>
                          {cleaning.booking.nextIn ? (
                            <span>
                              <i>
                                {t("list.NextIn")} {moment(cleaning.booking.nextIn).format("DD/MM HH:mm")}
                              </i>
                            </span>
                          ) : null}{" "}
                          -{" "}
                        </>
                      )}
                      {cleaning.booking.numAdults ? (
                        <span>
                          <i>
                            {cleaning.booking.numAdults}, {cleaning.booking.numChildren}, {cleaning.booking.numInfants}
                          </i>
                        </span>
                      ) : null}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Typography>
                      <Person style={{ fontSize: 15 }} /> <b>{t("list.Cleaner")}:</b> {cleaning.cleaners.length ? cleaning.cleaners.join(", ") : <i>{t("list.NotAssigned")}</i>}
                      <br />
                    </Typography>
                    {this.state.accessLevel > 0 && (
                      <Typography>
                        <AccessTime style={{ fontSize: 15 }} /> {cleaning.typeId === 1 ? <b>{t("list.Out")}:</b> : <b>{t("list.Hour")}:</b>} {moment(cleaning.cleaningTime).format("HH:mm")} <br />
                      </Typography>
                    )}
                    {this.state.accessLevel > 0 && (
                      <>
                        <Typography>
                          {cleaning.booking.nextIn ? (
                            <span>
                              <ArrowForward
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.NextIn")}: </b> {moment(cleaning.booking.nextIn).format("DD/MM HH:mm")}
                            </span>
                          ) : null}
                        </Typography>
                        <Typography>
                          {cleaning.booking.nextOut ? (
                            <span>
                              <ArrowBack
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.NextOut")}: </b> {moment(cleaning.booking.nextOut).format("DD/MM HH:mm")}
                            </span>
                          ) : null}
                        </Typography>
                      </>
                    )}
                    <Typography>
                      <Assignment style={{ fontSize: 15 }} /> <b>{t("list.Scheduled")}:</b>{" "}
                      {cleaning.cleaningTimeScheduled ? moment(cleaning.cleaningTimeScheduled).format("HH:mm") : <i>{t("list.NoTimeScheduled")}</i>} <br />
                    </Typography>
                    <Typography>
                      {cleaning.booking.numAdults ? (
                        <span>
                          <People
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.Pax")}:</b> {cleaning.booking.numAdults}, {cleaning.booking.numChildren}, {cleaning.booking.numInfants}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {bedConfig.length ? (
                        <span>
                          <Bed
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.BedConfig")}:</b> {bedConfig}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      <Place style={{ fontSize: 15 }} /> <b>{t("list.Address")}:</b>{" "}
                      <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(cleaning.property.propertyAddress)}>
                        {cleaning.property.propertyAddress}, {cleaning.property.propertyFloor} - {cleaning.property.propertyDoor}
                      </a>
                      {cleaning.property.propertyBuzzerInfo && " (" + cleaning.property.propertyBuzzerInfo + ")"}
                      <br />
                    </Typography>
                    <Typography>
                      <Bathtub style={{ fontSize: 15 }} /> <b>{t("list.Bathrooms")}:</b> {cleaning.property.propertyNumberBathrooms}
                      <br />
                    </Typography>
                    <Typography>
                      {cleaning.cleaningNotes ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.CleaningNotes")}:</b> {cleaning.cleaningNotes}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {cleaning.cleaningDepartureNotes ? (
                        <span
                          style={{
                            fontSize: 20,
                            color: "red"
                          }}
                        >
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.CleaningDepartureNotes")}:</b> {cleaning.cleaningDepartureNotes}
                        </span>
                      ) : null}
                    </Typography>
                    {this.state.accessLevel > 0 && (
                      <Typography>
                        {cleaning.property.propertyNotes ? (
                          <span>
                            <Note
                              style={{
                                fontSize: 15,
                                color: 'red' 
                              }}
                            />{" "}
                            <b style={{ color: 'red' }}>{t("list.PropertyNotes")}:</b> <span className="new-line" style={{ color: 'red' }}> {cleaning.property.propertyNotes}</span>
                          </span>
                        ) : null}
                      </Typography>
                    )}
                    <Typography>
                      {cleaning.property.propertyCleaningCompany ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.CleaningCompany")}:</b> {cleaning.property.propertyCleaningCompany}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {cleaning.property.propertyLaundryCompany ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.LaundryCompany")}:</b> {cleaning.property.propertyLaundryCompany}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {cleaning.property.propertyLaundryOwner ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.LaundryOwner")}:</b> {cleaning.property.propertyLaundryOwner}
                        </span>
                      ) : null}
                    </Typography>

                    {this.state.accessLevel === 0 && (
                      <>
                        <Typography>
                          {cleaning.property.propertyNumDoubleBeds ? (
                            <span>
                              <KingBed
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.DoubleBed")}:</b> {cleaning.property.propertyNumDoubleBeds}
                            </span>
                          ) : null}
                        </Typography>

                        <Typography>
                          {cleaning.property.propertyNumSingleBeds ? (
                            <span>
                              <SingleBed
                                style={{
                                  fontSize: 17
                                }}
                              />{" "}
                              <b>{t("list.SingleBed")}:</b> {cleaning.property.propertyNumSingleBeds}
                            </span>
                          ) : null}
                        </Typography>

                        <Typography>
                          {cleaning.property.propertyNumSingleSofaBeds ? (
                            <span>
                              <EventSeat
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.DoubleSofa")}:</b> {cleaning.property.propertyNumSingleSofaBeds}
                            </span>
                          ) : null}
                        </Typography>

                        <Typography>
                          {cleaning.property.propertyNumDoubleSofaBeds ? (
                            <span>
                              <EventSeat
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.SingleSofa")}:</b> {cleaning.property.propertyNumDoubleSofaBeds}
                            </span>
                          ) : null}
                        </Typography>

                        <Typography>
                          {cleaning.property.propertyNumberBathrooms ? (
                            <span>
                              <BathTub
                                style={{
                                  fontSize: 15
                                }}
                              />{" "}
                              <b>{t("list.Bathrooms")}:</b> {cleaning.property.propertyNumberBathrooms}
                            </span>
                          ) : null}
                        </Typography>
                      </>
                    )}
                    <Typography>
                      {cleaning.typeId === "2" && cleaning.booking.hours ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.Hours")}:</b> {cleaning.booking.hours}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {cleaning.typeId === "2" ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.Towels")}:</b> {cleaning.booking.towels === 1 ? t("list.Yes") : t("list.No")}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography>
                      {cleaning.typeId === "2" ? (
                        <span>
                          <Note
                            style={{
                              fontSize: 15
                            }}
                          />{" "}
                          <b>{t("list.BedLinen")}:</b> {cleaning.booking.bedlinen === 1 ? t("list.Yes") : t("list.No")}
                        </span>
                      ) : null}
                    </Typography>

                    {/* {cleaning.booking.numSofaBeds > 0 && ( */}
                    {showSofaBedUploader && (
                      <div className={cleaning.booking.proofs.sofaBed ? classes.proofsDone : classes.proofs}>
                        <Typography>{t("list.SofaBedImage")}</Typography>

                        <ImageUploader
                          cleaningsList={this.state.cleaningsList}
                          updateList={this.updateList}
                          t={t}
                          image={cleaning.booking.proofs.sofaBed}
                          type={1}
                          bookingId={cleaning.booking.nextBookingId}
                          cleaningId={cleaning.cleaningId}
                        />
                      </div>
                    )}

                    {showSofaBedLinenUploader && (
                      <div className={cleaning.booking.proofs.sofaBed ? classes.proofsDone : classes.proofs}>
                        <Typography>{t("list.SofaBedLinen")}</Typography>

                        <ImageUploader
                          cleaningsList={this.state.cleaningsList}
                          updateList={this.updateList}
                          t={t}
                          image={cleaning.booking.proofs.sofaBed}
                          type={1}
                          bookingId={cleaning.booking.nextBookingId}
                          cleaningId={cleaning.cleaningId}
                        />
                      </div>
                    )}

                    {(hasBabyCot || thereAreInfants) && (
                      <div className={cleaning.booking.proofs.babyCot ? classes.proofsDone : classes.proofs}>
                        <Typography>{t("list.BabyCotImage")}</Typography>

                        <ImageUploader
                          cleaningsList={this.state.cleaningsList}
                          updateList={this.updateList}
                          t={t}
                          image={cleaning.booking.proofs.babyCot}
                          type={2}
                          bookingId={cleaning.booking.nextBookingId}
                          cleaningId={cleaning.cleaningId}
                        />
                      </div>
                    )}
                    {cleaning.booking.keyPrep > 0 && (
                      <div className={cleaning.booking.proofs.keyPrep ? classes.proofsDone : classes.proofs}>
                        <Typography>{t("list.KeyImage")}</Typography>

                        <ImageUploader
                          cleaningsList={this.state.cleaningsList}
                          updateList={this.updateList}
                          t={t}
                          image={cleaning.booking.proofs.keyPrep}
                          type={3}
                          bookingId={cleaning.booking.nextBookingId}
                          cleaningId={cleaning.cleaningId}
                        />
                      </div>
                    )}
                    {/* {cleaning.property.checkinProcedureId === 34 && cleaning.booking.nextRefId && (
                                            <div className={cleaning.booking.proofs.keyBoxCode ? classes.proofsDone : classes.proofs}>
                                                <Typography>{t("list.KeyBoxCodeImage")}</Typography>

                                                <ImageUploader
                                                    cleaningsList={this.state.cleaningsList}
                                                    updateList={this.updateList}
                                                    t={t}
                                                    image={cleaning.booking.proofs.keyBoxCode}
                                                    type={4}
                                                    bookingId={cleaning.booking.nextBookingId}
                                                    cleaningId={cleaning.cleaningId}
                                                />
                                            </div>
                                        )} */}
                    {this.state.accessLevel > 0 && (
                      <>
                        {needsSofaBed || needsBabyCot ? ( // Add needsKeyBoxCode if we introduce that feature again
                          <FormControl className={classes.formControl}>
                            <FormLabel component="legend">{t("list.CleaningStatus")}</FormLabel>
                            <RadioGroup
                              name="status"
                              aria-label={t("list.CleaningStatus")}
                              value={cleaning.statusId}
                              onChange={e => {
                                this.changeTaskStatus(parseInt(e.target.value), cleaning);
                              }}
                            >
                              <FormControlLabel value={0} control={<Radio />} label={t("list.StatusPending")} />
                              <FormControlLabel value={2} control={<Radio />} label={t("list.StatusStarted")} />
                            </RadioGroup>
                            {needsSofaBed && <FormHelperText>{t("list.SofaBedMissing")}</FormHelperText>}
                            {needsBabyCot && <FormHelperText>{t("list.BabyCotMissing")}</FormHelperText>}
                            {/* {needsKeyBoxCode && <FormHelperText>{t("list.KeyBoxCodeMissing")}</FormHelperText>} */}
                          </FormControl>
                        ) : (
                          <FormControl className={classes.formControl}>
                            <FormLabel component="legend">{t("list.CleaningStatus")}</FormLabel>
                            <RadioGroup
                              name="status"
                              aria-label={t("list.CleaningStatus")}
                              value={cleaning.statusId}
                              onChange={e => {
                                this.changeTaskStatus(parseInt(e.target.value), cleaning);
                              }}
                            >
                              <FormControlLabel value={0} control={<Radio />} label={t("list.StatusPending")} />
                              <FormControlLabel value={2} control={<Radio />} label={t("list.StatusStarted")} />
                              <FormControlLabel value={1} control={<Radio />} label={t("list.StatusDone")} />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </>
                    )}
                    <div className={classes.grow}>
                      {cleaning.checkoutPdf && (
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            marginRight: 15
                          }}
                          color="primary"
                          className={classes.button}
                          href={cleaning.checkoutPdf}
                        >
                          {t("list.ViewCheckout")}
                        </Button>
                      )}
                      {this.state.accessLevel > 0 ? (
                        <>
                          {cleaning.property.propertyHasSmartLock > 0 && (
                            <div className={classes.lock}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={
                                  this.state.pressCount[2].count === 1 && !this.state.front_loading
                                    ? {
                                        backgroundColor: "red"
                                      }
                                    : null
                                }
                                disabled={!this.state.submitEnabled || cleaning.property.propertyHasSmartLock === 1}
                                onClick={() => {
                                  this.handleOpenDoor(cleaning.property.propertyId, 2, cleaning);
                                }}
                              >
                                {this.state.front_loading ? <CircularProgress size={24} /> : this.state.pressCount[2].count === 1 ? t("list.PressAgain") : t("list.FrontDoor")}
                              </Button>

                              <br />
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={
                                  this.state.pressCount[1].count === 1 && !this.state.front_loading
                                    ? {
                                        backgroundColor: "red"
                                      }
                                    : null
                                }
                                disabled={!this.state.submitEnabled || cleaning.property.propertyHasSmartLock === 3}
                                onClick={() => {
                                  this.handleOpenDoor(cleaning.property.propertyId, 1, cleaning);
                                }}
                              >
                                {this.state.apt_loading ? <CircularProgress size={24} /> : this.state.pressCount[1].count === 1 ? t("list.PressAgain") : t("list.AptDoor")}
                              </Button>

                              <Typography style={{ paddingTop: 5 }}>{t("list.UnlockNotice")}</Typography>
                            </div>
                          )}
                          {cleaning.needsCheckout === 1 ? (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{
                                marginRight: 15
                              }}
                              className={classes.button}
                              onClick={() => {
                                this.startCheckout(cleaning.property.propertyId);
                              }}
                            >
                              {t("list.Checkout")}
                            </Button>
                          ) : null}

                          {cleaning.booking && cleaning.booking.bookingId !== null && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              style={
                                this.state.accessLevel !== 0
                                  ? {
                                      marginRight: 15
                                    }
                                  : null
                              }
                              onClick={() => {
                                this.props.navigate("/dirty-apartment/" + cleaning.booking.bookingId + "");
                              }}
                            >
                              {t("list.DirtyApartment")}
                            </Button>
                          )}

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={
                              this.state.accessLevel !== 0
                                ? {
                                    marginRight: 15
                                  }
                                : null
                            }
                            onClick={() => {
                              this.props.navigate("/company/" + cleaning.cleaningId + "");
                            }}
                          >
                            {t("list.Company")}
                          </Button>
                        </>
                      ) : null}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 15
                        }}
                        className={classes.button}
                        onClick={() => {
                          this.props.navigate("/laundry/" + cleaning.cleaningId + "");
                        }}
                      >
                        {t("list.Laundry")}
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          marginRight: 15
                        }}
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          this.props.navigate("/maintenance/" + cleaning.property.propertyId + "");
                        }}
                      >
                        {t("list.Maintenance")}
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          marginRight: 15
                        }}
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          this.props.navigate("/lost-and-found/" + cleaning.property.propertyId + "");
                        }}
                      >
                        {t("list.LostFound")}
                      </Button>

                      {this.state.accessLevel > 1 && (
                        <>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                              this.props.navigate("/edit/" + cleaning.cleaningId + "");
                            }}
                          >
                            {t("list.Edit")}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                              marginLeft: 15
                            }}
                            className={classes.button}
                            onClick={() => {
                              this.props.navigate("/log/" + cleaning.cleaningId + "");
                            }}
                          >
                            {t("list.Log")}
                          </Button>
                        </>
                      )}

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{
                          marginLeft: 15
                        }}
                        href={`https://www.stay-u-nique.com/es/apartamento-${cleaning.property.propertyId}`}
                      >
                        {t("list.SuLink")}
                      </Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>

        {/* Filters */}
        <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
          <FiltersDrawer filters={this.state.filters} accessLevel={this.state.accessLevel} handleSelect={this.handleSelect} />
        </SwipeableDrawer>

        <Dialog open={this.state.impossibleDialogOpen} onClose={this.closeImpossibleDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t("list.CheckoutImpossible")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("list.CheckoutImpossibleDesc")}</DialogContentText>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.startCheckout(this.state.propertyId);
              }}
            >
              {t("list.Checkout")}
            </Button>
            <TextField
              autoFocus
              value={this.state.checkoutImpossibleReason}
              multiline
              onChange={this.handleTextChange}
              margin="dense"
              name="checkoutImpossibleReason"
              label={t("list.CheckoutImpossibleReason")}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.submitReason} color="primary" disabled={this.state.checkoutImpossibleReason.length === 0}>
              {t("misc.Submit")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CleaningsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withSnackbar(withStyles(styles)(CleaningsList))));
