import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, LinearProgress } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-multi-lang";
import fetchClient from "../utils/fetchClient";
import moment from "moment";

const Log = () => {
  // ** Hooks
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  // ** States
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const statuses = {
    0: t("list.StatusPending"),
    2: t("list.StatusStarted"),
    1: t("list.StatusDone")
  };

  const fetchLogs = () => {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("/ca/log/" + params.id)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const initialize = async () => {
    let notes = await fetchLogs();

    setLogs(notes.data.reverse());
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
            style={{ marginLeft: -12, marginRight: 20 }}
            color="inherit"
            aria-label={t("misc.Back")}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {!loading ? t("titles.Log") : t("titles.Loading") + "..."}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress color="secondary" />}

      <div style={{ padding: "1em" }}>
        {!loading ? (
          <div>
            {logs && logs.length > 0 ? (
              logs.map((log, index) => {
                return (
                  <div key={index}>
                    <p key={log.trackingId}>
                      {moment(log.trackingDate).format("MM/DD/YY HH:mm")} {log.changedByName} {t("log.ChangedStatusTo")} {statuses[log.statusId]}
                    </p>
                  </div>
                );
              })
            ) : (
              <p> {t("log.NoLog")}</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Log;
