import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

setDefaultTranslations({ es, en });
setDefaultLanguage("es");

var caSettings = JSON.parse(localStorage.getItem("caSettings"));
if (caSettings) {
  if (caSettings.language) {
    setLanguage(caSettings.language);
  }
} else {
  localStorage.setItem("caSettings", JSON.stringify({ language: "es" }));
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <SnackbarProvider
      ref={notistackRef}
      action={key => (
        <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <App />
    </SnackbarProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);
