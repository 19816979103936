import { createContext, useReducer } from "react";

const initialState = {
  user: null
};

const handlers = {
  SET_USER: (state, action) => {
    const { user } = action.payload;
    return { ...state, user };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  setUser: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUser = user =>
    dispatch({
      type: "SET_USER",
      payload: {
        user
      }
    });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
