import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, LinearProgress, InputLabel } from "@mui/material";

import ArrowBack from "@mui/icons-material/ArrowBack";
import { TimePicker } from "@material-ui/pickers";
import { useTranslation, getLanguage } from "react-multi-lang";
import moment from "moment";
import fetchClient from "../utils/fetchClient";

// ** Third party components
import ReactSelect from "react-select";

const localeMap = {
  en: "en",
  es: "es"
};

function flatten(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val.options) ? acc.concat(flatten(val.options)) : acc.concat(val)), []);
}

function getValue(opts, val, isMulti) {
  const defaultGetOptionValue = opt => opt.value;

  if (val === undefined) return undefined;

  const options = flatten(opts);
  const value = isMulti ? options.filter(o => val.includes(defaultGetOptionValue(o))) : options.find(o => defaultGetOptionValue(o) === val);

  return value;
}

const Edit = () => {
  const currentLang = getLanguage();

  // ** Hooks
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // ** States
  const [cleaning, setCleaning] = useState({ scheduledDate: null, cleaners: [] });
  const [cleanersList, setCleanersList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    moment.locale(currentLang);
  }, [currentLang]);

  const handleDateChange = date => {
    const newCleaning = { ...cleaning, scheduledDate: moment(date).format("YYYY-MM-DD HH:mm") };
    setCleaning(newCleaning);
    saveChanges(newCleaning);
  };

  const fetchCleaning = async () => {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("/ca/cleaning/" + params.id)
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const fetchCleaners = async () => {
    return new Promise(function (resolve, reject) {
      fetchClient
        .get("ca/cleaners")
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const handleSelect = val => {
    const cleaners = val.map(option => option.value);

    const newCleaning = { ...cleaning, cleaners };
    setCleaning(newCleaning);
    saveChanges(newCleaning);
  };

  const saveChanges = (cleaningParam = null) => {
    const id = cleaningParam ? cleaningParam.cleaningId : cleaning.cleaningId;

    fetchClient.post("ca/update/" + id, cleaningParam || cleaning).catch(function (error) {
      console.log(error);
    });
  };

  const initialize = async () => {
    let cleaning = await fetchCleaning();
    let cleaners = await fetchCleaners();

    setCleaning(prev => ({ ...prev, ...cleaning }));
    setCleanersList(cleaners);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
            style={{ marginLeft: -12, marginRight: 20 }}
            color="inherit"
            aria-label={t("misc.Back")}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {!loading ? t("titles.Edit") : t("titles.Loading") + "..."}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress color="secondary" />}

      <div style={{ padding: "1em" }}>
        {!loading && cleanersList && cleanersList.length > 0 ? (
          <div>
            <div style={{ marginBottom: "1.5em" }}>
              <TimePicker
                ampm={false}
                label={t("edit.ScheduledDate")}
                required
                locale={localeMap[currentLang]}
                fullWidth={true}
                format="HH:mm"
                autoOk={true}
                value={cleaning.scheduledDate}
                onChange={handleDateChange}
              />
            </div>
            <div
              className={{
                padding: "1em",
                marginTop: "1em",
                marginBottom: "1em"
              }}
            >
              <InputLabel shrink style={{ paddingBottom: 5 }}>
                {t("edit.Cleaner")}
              </InputLabel>

              <ReactSelect
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#70abdb",
                    primary50: "#5b9fd6",
                    primary75: "#4793d1",
                    primary: "#3388cc"
                  }
                })}
                isClearable
                isMulti
                defaultValue={getValue(
                  cleanersList.map(option => ({
                    value: option.cleanerId,
                    label: option.cleanerName
                  })),
                  cleaning.cleaners,
                  true
                )}
                name="cleaners"
                options={cleanersList.map(option => ({
                  value: option.cleanerId,
                  label: option.cleanerName
                }))}
                classNamePrefix="react-select"
                onChange={handleSelect}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Edit;
