import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ** Material UI
import { AppBar, Toolbar, Typography, TextField, Button } from "@mui/material";
import { Assignment } from "@mui/icons-material";

// ** Third party components
import { useSnackbar } from "notistack";
import { useTranslation } from "react-multi-lang";

// ** Services
import AuthService from "../../services/auth/AuthService";

const initialValues = {
  username: process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEFAULT_USER : "",
  password: process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEFAULT_PASSWORD : ""
};

export const Login = props => {
  const auth = new AuthService();

  // ** Hooks
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // ** States
  const [credentials, setCredentials] = useState(initialValues);

  const handleFormSubmit = e => {
    e.preventDefault();

    auth
      .login(credentials.username, credentials.password)
      .then(res => {
        navigate("/");
      })
      .catch(err => {
        enqueueSnackbar(t("login.Error"), { variant: "error", preventDuplicate: true });
      });
  };

  const handleChange = ({ target }) => {
    setCredentials({ ...credentials, [target.name]: target.value });
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Assignment />
          <Typography variant="h5" color="inherit">
            cleaningApp
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate autoComplete="off" onSubmit={handleFormSubmit} style={{ padding: "0.6em" }}>
        <TextField required id="username" name="username" label={t("login.Username")} margin="normal" onChange={handleChange} value={credentials.username} />
        <br />

        <TextField required id="password" name="password" label={t("login.Password")} type="password" margin="normal" onChange={handleChange} value={credentials.password} />
        <br />
        <Button type="submit" variant="contained" color="primary">
          {t("login.Login")}
        </Button>
      </form>
    </div>
  );
};

export default Login;
