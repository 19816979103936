import React from "react";

// ** Material UI
import { AppBar, Toolbar, Typography, IconButton, Badge, useTheme } from "@mui/material";
import { DatePicker } from "@material-ui/pickers";

// ** Material UI Icons
import Today from "@mui/icons-material/Restore";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import MenuIcon from "@mui/icons-material/Menu";
import Assignment from "@mui/icons-material/Assignment";
import FilterList from "@mui/icons-material/FilterList";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// ** Third party components
import { useTranslation } from "react-multi-lang";

export const CleaningAppBar = ({
  isMenuOpen,
  goToToday,
  saveStandards,
  toggleDrawer,
  handleMenuOpen,
  handleDatePrev,
  handleDateChange,
  handleDateNext,
  currentLang,
  standards,
  filtersCount,
  filters
}) => {
  // ** Hooks
  const theme = useTheme();
  const t = useTranslation();

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Assignment style={{ marginRight: 5 }} />
        <Typography variant="h6" color="inherit" noWrap>
          cleaningApp
        </Typography>

        <div style={{ flexGrow: 1 }} />
        <IconButton color="inherit" onClick={goToToday} aria-label={t("misc.Today")}>
          <Today />
        </IconButton>
        <IconButton color="inherit" onClick={saveStandards} aria-label={t("list.Standards")}>
          <Badge variant="dot" color="secondary" invisible={!standards.updated}>
            <PictureAsPdf />
          </Badge>
        </IconButton>

        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={toggleDrawer} color="inherit">
          <Badge badgeContent={filtersCount} color="secondary">
            <FilterList />
          </Badge>
        </IconButton>
        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={handleMenuOpen} color="inherit">
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Toolbar disableGutters={true} style={{ backgroundColor: "rgb(232, 232, 232)" }}>
        <IconButton onClick={handleDatePrev} style={{ marginLeft: 5 }} aria-label={t("misc.Previous")}>
          <KeyboardArrowLeft />
        </IconButton>

        <div
          style={{
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
            width: 90
          }}
        >
          <DatePicker
            InputProps={{
              disableUnderline: true
            }}
            locale={`${currentLang}`}
            fullWidth={true}
            format="DD/MM/YYYY"
            autoOk={true}
            value={filters.viewDate}
            onChange={handleDateChange}
          />
        </div>

        <IconButton onClick={handleDateNext} style={{ marginRight: 5 }} aria-label={t("misc.Next")}>
          <KeyboardArrowRight />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
