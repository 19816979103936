import React from "react";

// ** Third party components
import ReactSelect from "react-select";

export const Select = ({ defaultValue, options, ...otherProps }) => {
  return (
    <ReactSelect
      defaultValue={
        defaultValue && {
          value: defaultValue,
          label: options.find(o => o.value === defaultValue)?.label || "No Label"
        }
      }
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#70abdb",
          primary50: "#5b9fd6",
          primary75: "#4793d1",
          primary: "#3388cc"
        }
      })}
      options={options}
      {...otherProps}
    />
  );
};
