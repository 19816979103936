import React, { useState, useEffect } from "react";

// ** Material UI
import { Typography } from "@mui/material";

// ** Third party components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-multi-lang";

// ** Custom components
import { Select } from "./SelectField";

// ** Assets
import dishwasher from "../dishwasher.png";
import amenities from "../amenities.png";

// ** Utils
import fetchClient from "../utils/fetchClient";

export const FiltersDrawer = ({ filters, accessLevel, handleSelect }) => {
  // ** Hooks
  const t = useTranslation();

  // ** States
  const [propertiesList, setPropertiesList] = useState([]);
  const [buildingsList, setBuildingsList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [cleanersList, setCleanersList] = useState([]);

  const fetchProperties = async () => {
    const { data } = await fetchClient.get("ca/properties");
    setPropertiesList(data.data);
  };

  const fetchBuildings = async () => {
    const { data } = await fetchClient.get("buildings");
    setBuildingsList(data.data);
  };

  const fetchCompanies = async () => {
    const { data } = await fetchClient.get("ca/companies");
    setCompaniesList(data.data);
  };

  const fetchCleaners = async () => {
    const { data } = await fetchClient.get("ca/cleaners");
    setCleanersList(data.data);
  };

  const fetchFiltersData = async () => {
    await Promise.all([fetchProperties(), fetchBuildings(), fetchCompanies(), fetchCleaners()]);
  };

  useEffect(() => {
    fetchFiltersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div tabIndex={0} role="button" styles={{ width: 250 }}>
      <div style={{ padding: "0.2em 0.5em" }}>
        <Typography variant="h5" color="inherit">
          {t("list.Filters")}
        </Typography>
        <Typography variant="h6" color="inherit">
          {t("list.Property")}
        </Typography>

        <Select
          isClearable
          name="viewProperty"
          options={propertiesList.map(option => ({
            value: option.propertyId,
            label: option.propertyName
          }))}
          defaultValue={filters.viewProperty}
          classNamePrefix="react-select"
          onChange={handleSelect}
        />
      </div>

      <div style={{ padding: "0.2em 0.5em" }}>
        <Typography variant="h6" color="inherit">
          {t("list.Building")}
        </Typography>

        <Select
          isClearable
          name="viewBuilding"
          options={buildingsList.map(option => ({
            value: option.buildingId,
            label: option.buildingName
          }))}
          defaultValue={filters.viewBuilding}
          classNamePrefix="react-select"
          onChange={handleSelect}
        />
      </div>
      {accessLevel > 2 && (
        <>
          <div style={{ padding: "0.2em 0.5em" }}>
            <Typography variant="h6" color="inherit">
              {t("list.CleaningCompany")}
            </Typography>

            <Select
              isClearable
              name="viewCleaningCompany"
              options={companiesList.map(option => ({
                value: option.companyId,
                label: option.companyName
              }))}
              defaultValue={filters.viewCleaningCompany}
              classNamePrefix="react-select"
              onChange={handleSelect}
            />
          </div>
        </>
      )}

      {accessLevel > 1 && (
        <div style={{ padding: "0.2em 0.5em" }}>
          <Typography variant="h6" color="inherit">
            {t("list.Cleaner")}
          </Typography>

          <Select
            isClearable
            name="viewCleaner"
            options={cleanersList.map(option => ({
              value: option.cleanerId,
              label: option.cleanerName
            }))}
            defaultValue={filters.viewCleaner}
            classNamePrefix="react-select"
            onChange={handleSelect}
          />
        </div>
      )}
      <div style={{ marginTop: 15, marginLeft: 15 }}>
        <FontAwesomeIcon style={{ color: "blue" }} icon="exclamation-circle" /> <span style={{ marginLeft: 10 }}>{t("list.PropertyNotes")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-triangle" /> <span style={{ marginLeft: 8 }}>{t("list.CleaningNotes")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "orange" }} icon="tools" /> <span style={{ marginLeft: 11 }}>{t("list.PendingMaintenanceTasks")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "red" }} icon="broom" /> <span style={{ marginLeft: 8 }}>{t("list.StatusPending")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "orange" }} icon="broom" /> <span style={{ marginLeft: 10 }}>{t("list.StatusStarted")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "green" }} icon="broom" /> <span style={{ marginLeft: 10 }}>{t("list.StatusDone")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "purple" }} icon="clipboard-check" /> <span style={{ marginLeft: 14 }}>{t("list.CleaningReport")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation" /> <span style={{ marginLeft: 16 }}>{t("list.CheckoutImpossible")}</span>
        <br />
        <FontAwesomeIcon style={{ color: "red" }} icon="heart" /> <span style={{ marginLeft: 16 }}>{t("list.SpecialCare")}</span>
        <br />
        <FontAwesomeIcon icon="home" /> <span style={{ marginLeft: 12 }}>{t("list.LongTerm")}</span>
        <br />
        <img height="16" width="18" style={{ verticalAlign: "-0.125em" }} alt={t("list.Dishwasher")} src={dishwasher} />
        <span style={{ marginLeft: 13 }}>{t("list.Dishwasher")}</span>
        <br />
      </div>
    </div>
  );
};
