import { Navigate } from "react-router-dom";

// ** Guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// ** Pages
import { Login } from "../pages/auth/Login";
import CleaningsListNew from "../pages/cleaning/CleaningsList";
import CleaningOverview from "../pages/cleaning/CleaningOverview";
import Maintenance from "../pages/maintenance/Maintenance";
import MaintenanceOverview from "../pages/maintenance/MaintenanceOverview";
import Company from "../pages/company/Company";
import CompanyOverview from "../pages/company/CompanyOverview";
import Laundry from "../pages/laundry/Laundry";
import LaundryOverview from "../pages/laundry/LaundryOverview";
import Edit from "../pages/Edit";
import Log from "../pages/Log";
import DirtyApartment from "../pages/DirtyApartment";
import LostAndFound from "../pages/LostAndFound";
import Settings from "../pages/Settings";

export const Routes = () => {
  return [
    {
      path: "/",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          index: true,
          element: (
            <AuthGuard>
              <CleaningsListNew />
            </AuthGuard>
          )
        },
        {
          path: "cleaning-overview",
          element: (
            <AuthGuard>
              <CleaningOverview />
            </AuthGuard>
          )
        },

        {
          path: "maintenance/:id",
          element: (
            <AuthGuard>
              <Maintenance />
            </AuthGuard>
          )
        },
        {
          path: "maintenance-overview",
          element: (
            <AuthGuard>
              <MaintenanceOverview />
            </AuthGuard>
          )
        },

        {
          path: "laundry/:id",
          element: (
            <AuthGuard>
              <Laundry />
            </AuthGuard>
          )
        },
        {
          path: "laundry-overview",
          element: (
            <AuthGuard>
              <LaundryOverview />
            </AuthGuard>
          )
        },

        {
          path: "company/:id",
          element: (
            <AuthGuard>
              <Company />
            </AuthGuard>
          )
        },
        {
          path: "company-overview",
          element: (
            <AuthGuard>
              <CompanyOverview />
            </AuthGuard>
          )
        },

        {
          path: "edit/:id",
          element: (
            <AuthGuard>
              <Edit />
            </AuthGuard>
          )
        },
        {
          path: "log/:id",
          element: (
            <AuthGuard>
              <Log />
            </AuthGuard>
          )
        },
        {
          path: "lost-and-found/:id",
          element: (
            <AuthGuard>
              <LostAndFound />
            </AuthGuard>
          )
        },
        {
          path: "dirty-apartment/:id",
          element: (
            <AuthGuard>
              <DirtyApartment />
            </AuthGuard>
          )
        },
        {
          path: "settings",
          element: (
            <AuthGuard>
              <Settings />
            </AuthGuard>
          )
        }
      ]
    },
    { path: "*", element: <Navigate to="/404" replace /> }
  ];
};
