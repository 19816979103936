import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { setLanguage, getLanguage, useTranslation } from "react-multi-lang";

const Settings = () => {
  // ** Hooks
  const t = useTranslation();
  const navigate = useNavigate();

  // ** States
  const [currentLang, setCurrentLang] = useState(getLanguage());

  const changeLang = lang => {
    setCurrentLang(lang);
    setLanguage(lang);
    localStorage.setItem("caSettings", JSON.stringify({ language: lang }));
  };

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
            style={{ marginLeft: -12, marginRight: 20 }}
            color="inherit"
            aria-label={t("misc.Back")}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {t("titles.Settings")}
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{ padding: "1em" }}>
        <Button color={currentLang === "en" ? "primary" : "secondary"} onClick={() => changeLang("en")} variant="contained" style={{ padding: "1em" }}>
          {t("languages.English")}
        </Button>
        <Button color={currentLang === "es" ? "primary" : "secondary"} onClick={() => changeLang("es")} variant="contained" style={{ padding: "1em" }}>
          {t("languages.Spanish")}
        </Button>
      </div>
    </div>
  );
};
export default Settings;
