import axios from "axios";
import { version } from "../../package.json";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");

    config.headers = {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      "X-CleaningApp-Version": version
    };

    return config;
  });

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );

  const errorHandler = error => {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  };

  const successHandler = response => {
    return response;
  };

  return instance;
};

export default fetchClient();
