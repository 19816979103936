import decode from "jwt-decode";
import fetchClient from "../../utils/fetchClient";

export default class AuthService {
    constructor(domain) {
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    login(username, password) {
        // Get a token
        return fetchClient
            .post(
                "login",
                JSON.stringify({
                    username,
                    password
                })
            )
            .then(this._checkStatus)
            .then(response => {
                this.setToken(response.data.token);
                return Promise.resolve(response);
            });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Math.round(new Date().getTime() / 1000)) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    setToken(token) {
        // Saves user token to localStorage
        localStorage.setItem("token", token);
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem("token");
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem("caSettings");
        localStorage.removeItem("caViewConfig");
        localStorage.removeItem("token");
    }

    getProfile() {
        return decode(this.getToken());
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response) {
            return response;
        } else {
            var error = new Error("Login Error");
            throw error;
        }
    }
}
